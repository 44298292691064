import WolfAddStep from '@components/ui/WolfAddStep';
import WolfCardsStep from '@components/ui/WolfCardsStep';
import { EditIcon24 } from '@components/ui/WolfIcons';
import { graphQlClient } from '@config/graphqlClient';
import PoliciesGraphQL from '@graphql/policy.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { getPoliciesRequest, Policy } from '@models/policy.model';
import { Process } from '@models/process.model';
import { Step, StepType } from '@models/step.model';
import { Trigger, TriggerType } from '@models/trigger.model';
import {
  ArrowDownwardOutlined,
  BoltOutlined,
  CalendarMonthOutlined,
  CheckBoxOutlined,
  CloseOutlined,
  GroupWorkOutlined,
  PolicyOutlined,
} from '@mui/icons-material';
import { Box, Card, Divider, Icon, Typography } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { getStepsInsideSwitchRepeat } from '@utils/getStepsInsideSwitchRepeat';
import { repeatRuleToText } from '@utils/repeat-rule-to-text';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import WolfDropdown from './ui/WolfDropdown';
import WolfInput from './ui/WolfInput';
import WolfTextLink from './ui/WolfTextLink';
import WolfTooltip from './ui/WolfTooltip';

interface ProcessSidebarProps {
  steps: any[];
  origProcess: Process | undefined;
  category: string;
  goal: string;
  generatedFromName: string;
  policies: Policy[];
  triggers: Trigger[];
  triggeredBy: Step[];
  handleSelectStep: (stepId: string) => void;
  handleEditStep: (stepId: string) => void;
  handleEditTriggers: () => void;
  handleNewStep: (
    item: { text: string; value: string },
    index?: number,
    context?: { id: string; stepsIds: string; name: string },
  ) => void;
  dragEndHandler: (source: number, destination: number, loopId?: string) => void;
  dragInsideOutsideHandler: (moveInside: boolean, itemId: string, containerId: string) => void;
  selectedStep: string;
  editMode: boolean;
  isGenerating?: boolean;
  handleProcessUpdate: (process: Process) => void;
  handleLinkProcessPolicies: (processId: string, policies: string[]) => void;
}

const ProcessSidebar: React.FC<ProcessSidebarProps> = ({
  origProcess,
  selectedStep,
  goal,
  generatedFromName,
  policies,
  category,
  triggers = [],
  triggeredBy = [],
  steps,
  editMode,
  isGenerating = false,
  dragEndHandler,
  dragInsideOutsideHandler,
  handleEditTriggers,
  handleSelectStep,
  handleEditStep,
  handleNewStep,
  handleProcessUpdate,
  handleLinkProcessPolicies,
}) => {
  const localeProcesses = useTranslation('processes');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const dispatch = useAppDispatch();

  const [isHoveringGoal, setIsHoveringGoal] = useState(false);
  const [isHoveringTriggers, setIsHoveringTriggers] = useState(false);
  const [isHoveringGeneratedFrom, setIsHoveringGeneratedFrom] = useState(false);
  const [isHoveringPolicies, setIsHoveringPolicies] = useState(false);
  const [isHoveringCategory, setIsHoveringCategory] = useState(false);
  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [isEditingPolicies, setIsEditingPolicies] = useState(false);
  const [tempGoal, setTempGoal] = useState(goal);
  const [tempCategory, setTempCategory] = useState(category);
  const [policyList, setPolicyList] = useState<{ text: string; value: string }[]>([]);
  const [selectedPolicies, setSelectedPolicies] = useState<{ text: string; value: string }[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingSub, setIsDraggingSub] = useState<string>('');

  const handleMouseEnterGoal = useCallback(() => setIsHoveringGoal(true), []);
  const handleMouseLeaveGoal = useCallback(() => setIsHoveringGoal(false), []);
  const handleMouseEnterTriggers = useCallback(() => setIsHoveringTriggers(true), []);
  const handleMouseLeaveTriggers = useCallback(() => setIsHoveringTriggers(false), []);
  const handleMouseEnterGeneratedFrom = useCallback(() => setIsHoveringGeneratedFrom(true), []);
  const handleMouseLeaveGeneratedFrom = useCallback(() => setIsHoveringGeneratedFrom(false), []);
  const handleMouseEnterPolicies = useCallback(() => setIsHoveringPolicies(true), []);
  const handleMouseLeavePolicies = useCallback(() => setIsHoveringPolicies(false), []);
  const handleMouseEnterCategory = useCallback(() => setIsHoveringCategory(true), []);
  const handleMouseLeaveCategory = useCallback(() => setIsHoveringCategory(false), []);
  const handleDragStart = useCallback((e: any) => {
    const isSwitchRepeat = steps.some(
      (item) =>
        item.stepId === e.draggableId && (item.stepType === StepType.SWITCH || item.stepType === StepType.REPEAT),
    );
    if (!isSwitchRepeat) {
      setIsDragging(true);
    }
  }, []);
  const handleDragStartSub = useCallback((stepId: string) => {
    setIsDraggingSub(stepId);
  }, []);

  useEffect(() => {
    if (policies) {
      setSelectedPolicies(policies.map((policy) => ({ text: policy.name, value: policy.policyId })));
    }
  }, [policies]);

  const onDragEndSub = (result: DropResult, stepId: string, stepsIds: string) => {
    setIsDraggingSub('');
    console.log('onDragEndSub', result);
    if (result?.destination?.droppableId?.includes('moveOutside')) {
      dragInsideOutsideHandler(false, result?.draggableId, stepId);
    } else {
      if (!result.destination) return;
      dragEndHandler(result.source.index, result.destination.index, stepId);
    }
  };

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    console.log('onDragEnd', result);
    if (result?.destination?.droppableId?.includes('moveInside')) {
      const stepId = result.destination.droppableId.split('---')[1];
      dragInsideOutsideHandler(true, result?.draggableId, stepId);
    } else {
      if (!result.destination) return;
      dragEndHandler(result.source.index, result.destination.index);
    }
  };

  const handleShare = (stepId: string) => {
    navigator.clipboard.writeText(window.location.href.split('?')[0] + '?' + 'stepId=' + stepId);
    dispatch(appendActionMessage({ message: localeActionMessages['linkCopied'], type: SnackType.DARK }));
  };

  const getPolicies = async () => {
    try {
      const data: getPoliciesRequest = await graphQlClient.request(PoliciesGraphQL.queries.getPolicies);
      setPolicyList(
        data?.getPolicies.map((item) => {
          return { text: item.name, value: item.policyId };
        }) || [],
      );
    } catch (e: any) {
      console.log(e);
    }
  };

  const mainSteps = filterStepsInsideSwitchRepeat(steps)
    .sort((a, b) => a.order - b.order)
    .map((step: Step, index: number) => ({
      stepId: step?.stepId,
      name: step?.name,
      type: step?.stepType,
      stepsIds: step?.stepData?.stepsIds,
      isWolfieGenerated: step?.isWolfieGenerated,
      position: (index + 1).toString(),
      order: step?.order,
    }));

  const loopSwitchSteps = steps
    .sort((a, b) => a.order - b.order)
    .filter((item) => item.stepType === StepType.SWITCH || item.stepType === StepType.REPEAT);

  const getSubsteps = (stepsIds: string, position: number, type: StepType) => {
    return getStepsInsideSwitchRepeat(stepsIds, steps)
      .sort((a, b) => a.order - b.order)
      .map((step: Step, idx: number) => ({
        stepId: step?.stepId,
        name: step?.name,
        type: type,
        stepsIds: step?.stepData?.stepsIds || '',
        position:
          type === StepType.SWITCH
            ? position + 1 + '.' + String.fromCharCode(idx + 65)
            : position + 1 + '.' + (idx + 1),
      }));
  };

  const getSubMaxOrder = (stepsIds: string): number => {
    return getStepsInsideSwitchRepeat(stepsIds, steps).sort((a, b) => (a.order > b.order ? -1 : 1))[0]?.order || 0;
  };

  const calendarTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.CALENDAR)
    .map((item) => (
      <Box className="flex items-center w-full my-1" key={item.triggerId}>
        <CalendarMonthOutlined />
        <WolfTooltip
          title={
            (item?.triggerData?.description || '') +
            (item?.triggerData?.recurrence
              ? ' - ' + repeatRuleToText(item?.triggerData?.recurrence)
              : localeProcesses['noRecurrence'])
          }
          placement="right">
          <Typography variant="body14" className="!ml-1">
            {item.name}
          </Typography>
        </WolfTooltip>
      </Box>
    ));

  const eventTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.EVENT)
    .map((item) => (
      <Box className="flex items-center w-full my-1" key={item.triggerId}>
        <BoltOutlined />
        <WolfTooltip title={item?.triggerData?.description} placement="right">
          <Typography variant="body14" className="!ml-1">
            {item.name}
          </Typography>
        </WolfTooltip>
      </Box>
    ));

  const triggeredFrom = triggeredBy?.map((item) => (
    <Box className="flex items-center w-full my-1" key={item?.stepId + 'triggeredBy'}>
      <GroupWorkOutlined />
      <WolfTooltip title={item?.process?.goal} placement="right">
        <Typography variant="body14" className="!ml-1">
          {item?.process?.name}
        </Typography>
      </WolfTooltip>
    </Box>
  ));

  const stepsList = mainSteps.map((item, index, array) => {
    const isSwitchRepeat = item.type === StepType.SWITCH || item.type === StepType.REPEAT;
    return (
      <div key={item?.stepId}>
        <WolfCardsStep
          selected={item?.stepId === selectedStep}
          item={item}
          index={index}
          onClick={() => handleSelectStep(item?.stepId)}
          onEdit={() => handleEditStep(item?.stepId)}
          dragDisabled={false}
          isEditing={editMode}
          onShare={() => handleShare(item?.stepId)}
          isWolfieGenerated={item.isWolfieGenerated}
          onSubAdd={() =>
            handleNewStep({ text: '', value: '' }, getSubMaxOrder(item?.stepsIds), {
              id: item?.stepId,
              stepsIds: item?.stepsIds,
              name: item?.name,
            })
          }
        />
        {isSwitchRepeat && (
          <>
            <DragDropContext
              onDragEnd={(result) => onDragEndSub(result, item?.stepId, item?.stepsIds)}
              onDragStart={() => handleDragStartSub(item?.stepId)}>
              <Droppable droppableId={'Substep' + item?.stepId}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Box className="ml-6">
                      {getSubsteps(item?.stepsIds, index, item?.type).map(
                        (subStep: any, subIndex: number, array: any) => (
                          <div key={subStep?.stepId}>
                            <WolfCardsStep
                              selected={subStep?.stepId === selectedStep}
                              key={subStep.name}
                              item={subStep}
                              index={subIndex}
                              onClick={() => handleSelectStep(subStep?.stepId)}
                              onEdit={() => handleEditStep(subStep?.stepId)}
                              onShare={() => handleShare(item?.stepId)}
                              drawLine={item.type === StepType.SWITCH}
                              substep={true}
                              isLast={subIndex === array.length - 1}
                              dragDisabled={false}
                              isEditing={editMode}
                              isWolfieGenerated={subStep.isWolfieGenerated}
                            />
                          </div>
                        ),
                      )}
                      {provided.placeholder}
                    </Box>
                  </div>
                )}
              </Droppable>
              <Droppable droppableId={'moveOutside'}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Card
                      className={
                        'ml-6 flex items-center justify-between !shadow-none ' +
                        (isDraggingSub === item?.stepId
                          ? ' p-3 h-16 border-1 !border-solid border-primaryFrozen'
                          : ' h-[1px] scale-y-0')
                      }>
                      <Typography variant="body12" className="flex items-center justify-center">
                        {localeProcesses['dropBelow'] + ' ' + localeProcesses['toMoveOutsideAsMainStep']}
                      </Typography>
                      <ArrowDownwardOutlined className="mx-1 !text-xs" />
                    </Card>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </div>
    );
  });

  const handleGoalUpdate = (e: any) => {
    setTempGoal(e.target.value);
  };

  const handleCategoryUpdate = (e: any) => {
    setTempCategory(e.target.value);
  };

  const handleEditPolicies = () => {
    setIsEditingPolicies(true);
    getPolicies();
  };

  const handleGoalSubmit = () => {
    console.log('handleGoalSubmit', tempGoal, goal, origProcess);
    if (tempGoal !== goal && origProcess) {
      handleProcessUpdate({
        ...origProcess,
        goal: tempGoal,
      });
    }
    setIsEditingGoal(false);
  };

  const handlePolicyChange = (policies: { text: string; value: string }[]) => {
    if (policies.length === 0) {
      setSelectedPolicies([]);
    } else {
      setSelectedPolicies([...policies] || []);
    }
  };

  const handlePolicySubmit = () => {
    console.log('handlePolicySubmit', selectedPolicies, policies, origProcess);
    setIsEditingPolicies(false);
    if (origProcess?.processId) {
      handleLinkProcessPolicies(
        origProcess?.processId,
        selectedPolicies.map((item) => item.value),
      );
    }
  };

  const handleCategorySubmit = () => {
    if (tempCategory !== category && origProcess) {
      handleProcessUpdate({
        ...origProcess,
        category: tempCategory,
      });
    }
    setIsEditingCategory(false);
  };

  const handleClearGeneratedFrom = () => {
    if (origProcess) {
      handleProcessUpdate({
        ...origProcess,
        generatedFromName: '',
        generatedFrom: undefined,
      });
    }
  };

  return (
    <Box className="px-4 bg-backgroundUI overflow-y-auto max-h-screen pb-32">
      <Box className="flex flex-wrap">
        <>
          <Box
            className="flex items-center w-full justify-start h-6"
            onMouseEnter={handleMouseEnterCategory}
            onMouseLeave={handleMouseLeaveCategory}>
            <Typography variant="body14semibold">{localeCommon['category']}</Typography>
            {isHoveringCategory && !isEditingCategory && (
              <Icon onClick={() => setIsEditingCategory(true)} className="ml-1 cursor-pointer p-0 text-sm">
                <EditIcon24 />
              </Icon>
            )}
          </Box>
          {isEditingCategory ? (
            <Box className="flex items-center gap-2 w-full">
              <WolfInput
                label=""
                value={tempCategory}
                autoFocus
                onChange={handleCategoryUpdate}
                onBlur={handleCategorySubmit}
                onKeyDown={(e) => e.key === 'Enter' && handleCategorySubmit()}
              />
              <Box className="flex items-center gap-2">
                <Icon
                  onClick={() => {
                    setTempCategory(category);
                    setIsEditingCategory(false);
                  }}
                  className="cursor-pointer p-0 text-sm">
                  <CloseOutlined />
                </Icon>
                <Icon onClick={handleGoalSubmit} className="cursor-pointer p-0 text-sm">
                  <CheckBoxOutlined />
                </Icon>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body14"
              className="!mb-3 w-full"
              onMouseEnter={handleMouseEnterCategory}
              onMouseLeave={handleMouseLeaveCategory}>
              {category || localeCommon['uncategorized']}
            </Typography>
          )}
        </>

        <>
          <Box
            className="flex items-center w-full justify-start h-6"
            onMouseEnter={handleMouseEnterGoal}
            onMouseLeave={handleMouseLeaveGoal}>
            <Typography variant="body14semibold">{localeCommon['goal']}</Typography>
            {isHoveringGoal && !isEditingGoal && (
              <Icon onClick={() => setIsEditingGoal(true)} className="ml-1 cursor-pointer p-0 text-sm">
                <EditIcon24 />
              </Icon>
            )}
          </Box>
          {isEditingGoal ? (
            <Box className="flex items-center justify-between gap-2 w-full">
              <WolfInput
                label=""
                fullWidth
                value={tempGoal}
                onChange={handleGoalUpdate}
                autoFocus
                onBlur={handleGoalSubmit}
              />
              <Box className="flex items-center gap-2">
                <Icon onClick={() => setIsEditingGoal(false)} className="cursor-pointer p-0 text-sm">
                  <CloseOutlined />
                </Icon>
                <Icon onClick={handleGoalSubmit} className="cursor-pointer p-0 text-sm">
                  <CheckBoxOutlined />
                </Icon>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body14"
              className="!mb-3 w-full"
              onMouseEnter={handleMouseEnterGoal}
              onMouseLeave={handleMouseLeaveGoal}>
              {goal}
            </Typography>
          )}
        </>
        {generatedFromName && (
          <>
            <Box
              className="flex items-center w-full justify-start h-6"
              onMouseEnter={handleMouseEnterGeneratedFrom}
              onMouseLeave={handleMouseLeaveGeneratedFrom}>
              <Typography variant="body14semibold">{localeProcesses['generatedFrom']}</Typography>
              {isHoveringGeneratedFrom && (
                <WolfTooltip title={localeProcesses['generatedFromClear']} placement="right">
                  <Icon className="ml-1 cursor-pointer p-0 text-sm" onClick={handleClearGeneratedFrom}>
                    <CloseOutlined />
                  </Icon>
                </WolfTooltip>
              )}
            </Box>
            <Typography variant="body14" className="!mb-3 w-full break-words break-all">
              {generatedFromName}
            </Typography>
          </>
        )}
        {
          <Box className="w-full" onMouseEnter={handleMouseEnterPolicies} onMouseLeave={handleMouseLeavePolicies}>
            <Box className="flex items-center w-full justify-start h-6">
              <Typography variant="body14semibold">{localeProcesses['policiesLinked']}</Typography>
              {isHoveringPolicies && !isEditingPolicies && (
                <Icon onClick={handleEditPolicies} className="ml-1 cursor-pointer p-0 text-sm">
                  <EditIcon24 />
                </Icon>
              )}
            </Box>
            {!isEditingPolicies &&
              policies.map((policy) => (
                <Box key={policy.policyId} className="flex items-center w-full justify-start h-6 mb-1">
                  <Box className="flex items-center justify-between gap-1">
                    <Icon className="text-xs">
                      <PolicyOutlined />
                    </Icon>
                    <Typography variant="body14">{policy.name}</Typography>
                  </Box>
                </Box>
              ))}
            {isEditingPolicies && (
              <Box className="flex items-center gap-2 min-w-40">
                <WolfDropdown
                  showClearButton={true}
                  label={
                    !selectedPolicies
                      ? localeProcesses['selectPolicy']
                      : selectedPolicies.map((item) => item.text).join(', ')
                  }
                  items={policyList}
                  onChange={handlePolicyChange}
                  initialSelectedItems={selectedPolicies.map((item) => item.value)}
                  showBorder={true}
                />
                <Box className="flex items-center gap-2">
                  <Icon onClick={() => setIsEditingPolicies(false)} className="cursor-pointer p-0 text-sm">
                    <CloseOutlined />
                  </Icon>
                  <Icon onClick={handlePolicySubmit} className="cursor-pointer p-0 text-sm">
                    <CheckBoxOutlined />
                  </Icon>
                </Box>
              </Box>
            )}
            {!policies ||
              (policies.length === 0 && !isEditingPolicies && (
                <Typography variant="body14" className="!mb-3 block cursor-pointer" onClick={handleEditPolicies}>
                  {localeProcesses['noPoliciesYet']}
                  <WolfTextLink component="span" size="14px" color="link" className="underline">
                    {' '}
                    {localeCommon['clickToAddOne']}
                  </WolfTextLink>
                </Typography>
              ))}
          </Box>
        }
        <>
          <Box
            className="flex items-center w-full justify-start h-6"
            onMouseEnter={handleMouseEnterTriggers}
            onMouseLeave={handleMouseLeaveTriggers}>
            <Typography variant="body14semibold">{localeCommon['triggers']}</Typography>
            {isHoveringTriggers && (
              <Icon onClick={handleEditTriggers} className="ml-1 cursor-pointer p-0 text-sm">
                <EditIcon24 />
              </Icon>
            )}
          </Box>
          <Box className="flex flex-wrap w-full !mb-3">
            {calendarTriggerList}
            {eventTriggerList}
            {triggeredFrom}
            {triggeredFrom.length === 0 && triggers.length === 0 && (
              <>
                <Typography variant="body14" className="!mt-3 block cursor-pointer" onClick={handleEditTriggers}>
                  {localeProcesses['noTriggersYet'] + ' '}{' '}
                  <WolfTextLink component="span" size="14px" color="link" className="underline">
                    {' '}
                    {localeCommon['clickToAddOne']}
                  </WolfTextLink>
                </Typography>
              </>
            )}
          </Box>
        </>
      </Box>
      <Box className="flex items-center w-full justify-start h-6">
        <Typography variant="body14semibold">{localeCommon['steps']}</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={(e) => handleDragStart(e)}>
        <Droppable droppableId="ProcessSidebar">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {stepsList}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {loopSwitchSteps.map((step, index) => (
          <Droppable droppableId={'moveInside---' + step.stepId} key={step.stepId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Card
                  className={
                    'mx-4 !shadow-none flex items-center justify-between ' +
                    (isDragging ? ' p-3 mb-4 h-16 border-1 !border-solid border-primaryFrozen' : ' h-[1px] scale-y-0')
                  }>
                  <Typography variant="body12" className="flex items-center justify-center">
                    {localeProcesses['dropBelow'] + ' ' + localeProcesses['toMoveInside'] + ' ' + step.name}
                  </Typography>
                  <ArrowDownwardOutlined className="mx-1 !text-xs" />
                </Card>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      <Box className="w-full max-w-80">
        <Divider />
        {!isGenerating && steps?.length > 0 ? (
          <WolfAddStep
            orientation="horizontal"
            forceExpandAndHover={true}
            hideline={true}
            handleNewStep={(newStep) => handleNewStep(newStep, steps[steps.length - 1]?.order + 1)}
          />
        ) : (
          <Typography variant="body14" className="!mt-3 block">
            {localeProcesses['noStepsYet']}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProcessSidebar;
