import CalendarRecurrence from '@components/drawers/CalendarRecurrence';
import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import WolfTextarea from '@components/ui/WolfTextarea';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { CalendarTrigger, Trigger } from '@models/trigger.model';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

declare namespace Intl {
  interface IntlStatic {
    [x: string]: any;
  }
}

export declare const Intl: Intl.IntlStatic;

interface AddCalendarTriggerFormProps {
  processName: string;
  initialData?: Trigger;
  saving: boolean;
  onClose: () => void;
  onSave: (calendartrigger?: CalendarTrigger) => void;
}

const AddCalendarTriggerForm: React.FC<AddCalendarTriggerFormProps> = ({
  processName,
  initialData,
  saving,
  onClose,
  onSave,
}) => {
  const timezones: string[] = Intl.supportedValuesOf('timeZone');
  const localeCalTrig = useTranslation('calTrig');
  const localeCommon = useTranslation('common');
  const localeProcessForm = useTranslation('processForm');
  const localeActionMessages = useTranslation('actionMessages');

  const dispatch = useDispatch();

  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(null);
  const [repeatInitialData, setRepeatInitialData] = useState<any>(null);
  const [calendarTrigger, setCalendarTrigger] = useState<CalendarTrigger | undefined>(initialData);

  useEffect(() => {
    if (initialData?.triggerData?.recurrence) {
      setRepeatInitialData(initialData?.triggerData.recurrence);
    }
    if (initialData?.triggerData?.timezone) {
      setSelectedTimezone(initialData?.triggerData.timezone);
    } else {
      setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    if (initialData?.triggerData?.startTime) {
      if (!isNaN(new Date(initialData?.triggerData.startTime || '').getTime())) {
        const newDate = new Date(initialData?.triggerData.startTime || '');
        const formattedDate = newDate.toISOString().slice(0, 16);
        setCalendarTrigger((prevState) => ({
          ...prevState,
          startTime: formattedDate,
        }));
      }
    }
    if (initialData?.triggerData?.endTime) {
      const newDate = new Date(initialData?.triggerData.endTime || '');
      if (!isNaN(newDate.getTime())) {
        const formattedDate = newDate.toISOString().slice(0, 16);
        setCalendarTrigger((prevState) => ({
          ...prevState,
          endTime: formattedDate,
        }));
      }
    }
  }, [initialData]);

  const handleTimezoneChange = (event: any, selected: any) => {
    setSelectedTimezone(selected);
    setCalendarTrigger({
      ...calendarTrigger,
      timezone: selected,
    });
  };

  const handleCalendarTriggerChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCalendarTrigger({
      ...calendarTrigger,
      [event.target.name]: event.target.value,
    });
  };

  const handleCalendarTriggerRRuleChange = (rrule: any) => {
    setCalendarTrigger({
      ...calendarTrigger,
      rrule,
    });
  };

  const handleCalendarTriggerDateChange = (type: string, value: string | null) => {
    if (type === 'startTime' && value) {
      const startDate = new Date(value);
      const endDate = new Date(startDate.getTime() + 30 * 60000);
      const endDateString = endDate.toISOString().slice(0, 16);

      setCalendarTrigger({
        ...calendarTrigger,
        startTime: value,
        endTime: endDateString,
      });
    } else {
      setCalendarTrigger({
        ...calendarTrigger,
        [type]: value,
      });
    }
  };

  const submit = () => {
    if (!calendarTrigger?.name) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['triggerNameRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    onSave(calendarTrigger);
    onClose();
  };

  return (
    <Box>
      <WolfInput fullWidth label={localeProcessForm['processName']} value={processName} disabled className="!mb-8" />

      <WolfInput
        fullWidth
        className="!mb-8"
        label={localeCalTrig['name'] + ' *'}
        secondaryLabel={localeCommon['required']}
        id="name-input"
        placeholder={localeCalTrig['namePlaceholder']}
        name="name"
        value={calendarTrigger?.name}
        onChange={handleCalendarTriggerChange}
      />
      <WolfTextarea
        label={localeProcessForm['description']}
        placeholder={localeProcessForm['description']}
        name="description"
        className="w-full resize-y !mb-8"
        value={calendarTrigger?.description || ''}
        onChange={handleCalendarTriggerChange}
      />
      {/* Check: Can be moved upstream if needed in more places */}
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Box className="flex mb-8 gap-8">
          <WolfInput
            fullWidth
            label={localeCalTrig['startTime']}
            name="startTime"
            value={calendarTrigger?.startTime}
            onChange={(event) => handleCalendarTriggerDateChange('startTime', event.target.value)}
            type="datetime-local"
          />

          <WolfInput
            fullWidth
            label={localeCalTrig['endTime']}
            name="endTime"
            value={calendarTrigger?.endTime}
            onChange={(event) => handleCalendarTriggerDateChange('endTime', event.target.value)}
            type="datetime-local"
          />
        </Box>
      </LocalizationProvider>
      <Typography variant="body14semibold" gutterBottom>
        {localeCalTrig['timezone']}
      </Typography>
      <Autocomplete
        className="mb-8"
        value={selectedTimezone}
        onChange={handleTimezoneChange}
        options={timezones}
        renderInput={(params) => <TextField {...params} variant="outlined" className="!h-10" />}
        isOptionEqualToValue={(option, value) => option === value}
        filterOptions={(options, { inputValue }) => {
          return options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
        }}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            padding: '2px !important',
            height: '40px',
            minHeight: '40px',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
          },
        }}
      />
      <Box className="mt-8">
        <CalendarRecurrence initialData={repeatInitialData} refreshRrule={handleCalendarTriggerRRuleChange} />
      </Box>

      <WolfInput
        className="mt-8"
        fullWidth
        label={localeCalTrig['guests']}
        secondaryLabel={localeCommon['optional']}
        id="guest-input"
        placeholder={localeCalTrig['guestsPlaceholder']}
        name="guests"
        value={calendarTrigger?.guests}
        onChange={handleCalendarTriggerChange}
      />

      <Box className="absolute border-solid border-l-0 border-r-0 border-b-0 border-primaryFrozen !h-16 bottom-0 left-0 w-full bg-white">
        <Box
          className="mt-auto bg-white flex justify-between items-center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}>
          <WolfButton variant="outlined" color="secondary" onClick={onClose}>
            {localeCommon['cancel']}
          </WolfButton>
          <WolfButton variant="contained" color="primary" disabled={saving} onClick={submit}>
            {localeCommon['save']}
          </WolfButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCalendarTriggerForm;
