import { Box, styled, Tab, Tabs, Typography } from '@mui/material';
import { TabProps } from '@mui/material/Tab';
import { useEffect, useRef, useState } from 'react';

interface WolfTabsProps {
  tabs: {
    name: string;
    beforeIcon?: React.ReactNode;
    afterIcon?: React.ReactNode;
    content: React.ReactNode;
  }[];
  selected?: number;
}

interface CustomTabProps extends TabProps {
  selected?: boolean;
}

const CustomTab = styled((props: CustomTabProps) => <Tab {...props} />)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  minWidth: 0,
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: selected ? 600 : 400,
  color: theme.palette.additionalPlaceholder.main,
  '&:hover': {
    color: theme.palette.frozenGrayShades7.main,
  },
  '&.Mui-selected': {
    color: theme.palette.frozenGrayShades8.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '&.MuiButtonBase-root': {
    minWidth: 'fit-content',
  },
}));

const WolfTabs: React.FC<WolfTabsProps> = ({ tabs, selected = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(selected);
  const tabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      tabRef.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  // If we add a new tab, we need to update the selected tab
  useEffect(() => {
    if (selectedTab >= tabs.length) {
      setSelectedTab(0);
    }
  }, [tabs.length]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="wolf tabs" centered={true}>
        {tabs.map((tab, index) => (
          <CustomTab
            key={index}
            selected={selectedTab === index}
            label={
              <Box display="flex" alignItems="center" tabIndex={0} ref={tabRef}>
                {tab.beforeIcon && <Box mr={1}>{tab.beforeIcon}</Box>}
                <Typography variant="body16" component={'div'} fontWeight={selectedTab === index ? 600 : 400}>
                  {tab.name}
                </Typography>
                {tab.afterIcon && <Box ml={1}>{tab.afterIcon}</Box>}
              </Box>
            }
          />
        ))}
      </Tabs>
      <Box mt={2}>
        {tabs.map((tab, index) => (
          <Box key={index} hidden={selectedTab !== index}>
            {tab.content}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WolfTabs;
