import TinyMCE from '@components/TinyMCE';
import WolfInput from '@components/ui/WolfInput';
import WolfTabs from '@components/ui/WolfTabs';
import WolfTextarea from '@components/ui/WolfTextarea';
import { graphQlClient } from '@config/graphqlClient';
import ProcessesGraphQL from '@graphql/process.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getProcessesWithAreaServiceRequest, LinkedProcess } from '@models/process.model';
import { StepType, TimingType } from '@models/step.model';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import WolfDropdown from './ui/WolfDropdown';

interface ProcessContentProps {
  newStepType?: StepType | null;
  parentCategory?: string;
  initialStepName: string;
  initialLinkedProcess: { processId: string; name: string };
  initialNotes: string;
  initialCondition: string;
  initialDescription: string;
  initialTimingType: TimingType;
  initialTimingName: string;
  initialTimingDescription: string;
  initialData?: any;
  updatingStep?: any;
}

interface ProcessListItem {
  processId: string;
  name: string;
  label: string;
  service?: {
    name?: string;
    area?: {
      name?: string;
    };
  };
  category?: string;
}

const ProcessContent: React.FC<ProcessContentProps> = ({
  newStepType = null,
  parentCategory = '',
  initialStepName,
  initialLinkedProcess,
  initialNotes,
  initialCondition,
  initialDescription,
  initialTimingType,
  initialTimingName,
  initialTimingDescription,
  initialData,
  updatingStep,
}) => {
  const editorRef: any = useRef(null);
  const localeProcessForm = useTranslation('processForm');
  const localeCommon = useTranslation('common');
  const localeStepItem = useTranslation('stepItem');

  const [stepName, setStepName] = useState(initialStepName);
  const [linkedProcess, setLinkedProcess] = useState<LinkedProcess>(initialLinkedProcess);
  const [notes, setNotes] = useState(initialNotes);
  const [condition, setCondition] = useState(initialCondition);
  const [description, setDescription] = useState(initialDescription);
  const [processesList, setProcessesList] = useState<ProcessListItem[]>([]);
  const [timingType, setTimingType] = useState<{ value: TimingType; text: string }>({
    value: TimingType.IMMEDIATE,
    text: localeStepItem['immediate'],
  });
  const [timingName, setTimingName] = useState(initialTimingName);
  const [timingDescription, setTimingDescription] = useState(initialTimingDescription);

  const timingTypes: { text: string; value: TimingType }[] = [
    { value: TimingType.IMMEDIATE, text: localeStepItem['immediate'] },
    { value: TimingType.EMAIL, text: localeStepItem['linkEmail'] },
    { value: TimingType.CALENDAR, text: localeStepItem['linkCalendar'] },
    { value: TimingType.OTHER, text: localeStepItem['linkOther'] },
  ];

  useEffect(() => {
    setLinkedProcess(initialLinkedProcess);
    updatingStep({ linked: initialLinkedProcess });
  }, [initialLinkedProcess]);

  useEffect(() => {
    setStepName(initialStepName);
    updatingStep({ name: initialStepName });
  }, [initialStepName]);

  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  useEffect(() => {
    setCondition(initialCondition);
    updatingStep({ condition: initialCondition });
  }, [initialCondition]);

  useEffect(() => {
    setDescription(initialDescription);
    updatingStep({ description: initialDescription });
  }, [initialDescription]);

  useEffect(() => {
    setTimingType({
      value: initialTimingType,
      text: timingTypes.find((item) => item.value === initialTimingType)?.text || localeStepItem['immediate'],
    });
    updatingStep({ timingType: initialTimingType });
  }, [initialTimingType]);

  useEffect(() => {
    setTimingName(initialTimingName);
    updatingStep({ timingName: initialTimingName });
  }, [initialTimingName]);

  useEffect(() => {
    setTimingDescription(initialTimingDescription);
    updatingStep({ timingDescription: initialTimingDescription });
  }, [initialTimingDescription]);

  useEffect(() => {
    getProcessesList();
  }, []);

  useEffect(() => {
    if (initialData) {
      setStepName(initialData.name);
      updatingStep({ name: initialData.name });
    }
  }, [initialData]);

  const handleStepNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStepName(event.target.value);
    updatingStep({ name: event.target.value });
  };
  const handleLinkedProcessChange = (event: any, selected: ProcessListItem | null) => {
    if (selected) {
      setLinkedProcess({
        name: selected.name,
        processId: selected.processId,
      });
      updatingStep({
        linked: {
          name: selected.name,
          processId: selected.processId,
        },
      });
    } else {
      setLinkedProcess({ name: '', processId: '' });
      updatingStep({
        linked: {
          name: '',
          processId: '',
        },
      });
    }
  };
  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
    updatingStep({ notes: event.target.value });
  };
  const handleConditionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCondition(event.target.value);
    updatingStep({ condition: event.target.value });
  };
  const handleDescriptionChange = (content: string) => {
    setDescription(content);
    updatingStep({ description: content });
  };

  const handleTimingChange = (timing: any) => {
    setTimingType(timing);
    if (timing.value !== TimingType.IMMEDIATE) {
      updatingStep({ timingType: timing.value });
    } else {
      updatingStep({ timingType: TimingType.IMMEDIATE, timingName: '', timingDescription: '' });
    }
  };

  const handleTimingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimingName(event.target.value);
    updatingStep({ timingName: event.target.value });
  };

  const handleTimingDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTimingDescription(event.target.value);
    updatingStep({ timingDescription: event.target.value });
  };

  const getProcessesList = async () => {
    try {
      const data: getProcessesWithAreaServiceRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesWithAreaService,
      );
      const transformedOptions = data.getProcessesWithAreaService.map((item) => {
        const areaName = item.service?.area?.name ?? '';
        const serviceName = item.service?.name ?? '';
        const categoryName = item.category ?? '';
        const optionName = item.name ?? '';
        return {
          ...item,
          label: `${areaName.slice(0, 15).trim() + (areaName.length > 15 ? '...' : '').trim()} - 
                  ${serviceName.slice(0, 15).trim() + (serviceName.length > 15 ? '...' : '').trim()} - 
                  ${categoryName.slice(0, 15).trim() + (categoryName.length > 15 ? '...' : '').trim()} - 
                  ${optionName.trim()}`,
        };
      });
      setProcessesList([
        ...transformedOptions.filter((item) => item.category === parentCategory),
        ...transformedOptions.filter((item) => item.category !== parentCategory),
      ]);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const tab1 = {
    name: localeProcessForm['stepInformation'],
    content: (
      <Box className="p-4 mx-auto" maxWidth={500}>
        <Box className="mb-6">
          <WolfInput
            label={localeProcessForm['stepName'] + ' *'}
            placeholder={localeProcessForm['stepName']}
            value={stepName}
            onChange={handleStepNameChange}
            fullWidth
          />
        </Box>
        {!(StepType.SWITCH === newStepType || StepType.REPEAT === newStepType) && (
          <>
            <Box className="mb-6">
              <Box className="flex justify-between mb-0">
                <Typography variant="body14semibold" color="primary">
                  {localeProcessForm['linkedProcess']}
                </Typography>
                <Typography variant="body14" color="primary">
                  {localeCommon['optional']}
                </Typography>
              </Box>
              <Autocomplete
                className="mb-8"
                value={
                  linkedProcess?.processId
                    ? processesList.find((item) => item.processId === linkedProcess.processId) || null
                    : null
                }
                onChange={handleLinkedProcessChange}
                options={processesList}
                isOptionEqualToValue={(option, value) => option?.processId === value?.processId}
                getOptionLabel={(option) => {
                  if (!option) return '';
                  return option.label?.trim().replace(/\s+/g, ' ') || '';
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="!h-10"
                    placeholder={localeProcessForm['selectProcess']}
                  />
                )}
                filterOptions={(options, { inputValue }) => {
                  const searchTerm = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option?.name?.toLowerCase().includes(searchTerm) ||
                      option?.service?.name?.toLowerCase().includes(searchTerm) ||
                      option?.service?.area?.name?.toLowerCase().includes(searchTerm),
                  );
                }}
                sx={{
                  '& .MuiAutocomplete-inputRoot': {
                    padding: '2px !important',
                    height: '40px',
                    minHeight: '40px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                  },
                }}
              />
            </Box>

            {linkedProcess?.processId && (
              <>
                <Box className="mb-6">
                  <Typography variant="body14semibold">{localeStepItem['linkedProcessTiming']}</Typography>
                  <Box className="border-2 border-primaryIcy border-solid rounded">
                    <WolfDropdown
                      label={timingType.text || 'a' || localeStepItem['linkedProcessTiming']}
                      items={timingTypes}
                      singleSelect={true}
                      onChange={(timing) => handleTimingChange(timing[0])}
                    />
                  </Box>
                </Box>
                {timingType.value !== TimingType.IMMEDIATE && (
                  <Box>
                    <Box className="mb-6">
                      <WolfInput
                        label={localeCommon['link'] + ' ' + localeCommon['name']}
                        value={timingName}
                        onChange={handleTimingNameChange}
                        fullWidth
                      />
                    </Box>

                    <Box className="mb-6">
                      <WolfTextarea
                        label={localeCommon['link'] + ' ' + localeCommon['description']}
                        value={timingDescription}
                        onChange={handleTimingDescriptionChange}
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Box className="mb-6">
              <WolfTextarea
                label={localeProcessForm['stepNotes']}
                secondaryLabel={localeCommon['optional']}
                placeholder={localeProcessForm['stepNotesExplain']}
                value={notes}
                customRows={2}
                onChange={handleNotesChange}
                fullWidth
              />
            </Box>
            <Box className="mb-6">
              <WolfTextarea
                label={localeProcessForm['stepCondition']}
                secondaryLabel={localeCommon['optional']}
                placeholder={localeProcessForm['stepConditionExplain']}
                value={condition}
                customRows={2}
                onChange={handleConditionChange}
                fullWidth
              />
            </Box>
          </>
        )}
      </Box>
    ),
  };

  const tab2 = {
    name: localeProcessForm['stepContent'],
    content: (
      <Box className="w-full h-full">
        <TinyMCE value={description} handleDescriptionChange={handleDescriptionChange} editorRef={editorRef} />
      </Box>
    ),
  };

  return (
    <Box className="bg-white w-full h-full grow h-full-minus-header">
      <WolfTabs tabs={[tab1, ...(StepType.SWITCH === newStepType || StepType.REPEAT === newStepType ? [] : [tab2])]} />
    </Box>
  );
};

export default ProcessContent;
