/* eslint-disable react/no-unescaped-entities */
import CustomModal from '@components/CustomModal';
import AddCalendarTriggerForm from '@components/drawers/AddCalendarTriggerForm';
import AddEventTriggerForm from '@components/drawers/AddEventTriggerForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import WolfButton from '@components/ui/WolfButton';
import { CalendarTriggerIcon20 } from '@components/ui/WolfIcons';
import WolfTriggerBox from '@components/ui/WolfTriggerBox';
import { graphQlClient } from '@config/graphqlClient';
import TriggersGraphQL from '@graphql/trigger.queries';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { SnackType } from '@models/common.model';
import { Process } from '@models/process.model';
import {
  CalendarTrigger,
  createOrUpdateTriggerRequest,
  deleteTriggerRequest,
  EventTrigger,
  Trigger,
  TriggerType,
} from '@models/trigger.model';
import { AddOutlined, ElectricBoltOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Icon, List, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';

interface AddTriggerFormProps {
  onClose: () => void;
  initialData?: Process;
}

const AddTriggerForm: React.FC<AddTriggerFormProps> = ({ onClose, initialData }) => {
  const localeProcessForm = useTranslation('processForm');
  const localeCommon = useTranslation('common');
  const dispatch = useDispatch();

  const [entityData, setEntityData] = useState<Partial<Process>>({
    name: '',
    goal: '',
    description: '',
    category: '',
    serviceId: '',
  });
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [triggers, setTriggers] = useState<Trigger[]>([]);
  const [addTrigger, setAddTrigger] = useState<TriggerType | null>(null);
  const [selectedEventTrigger, setSelectedEventTrigger] = useState<EventTrigger>();
  const [selectedCalendarTrigger, setSelectedCalendarTrigger] = useState<Trigger>();
  const [triggerSaving, setTriggerSaving] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState<boolean>(false);
  useUnsavedChangesWarning({ showWarning: hasUnsavedChanges });

  useEffect(() => {
    if (initialData) {
      setEntityData(initialData);
      if (initialData.triggers) {
        setTriggers(initialData.triggers);
      }
    }
  }, [initialData]);

  const _onClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    onClose();
  };

  // TODO: Call this from components
  const handleAddTrigger = async (calendarTrigger?: CalendarTrigger, eventTrigger?: EventTrigger) => {
    setTriggerSaving(true);
    if (!calendarTrigger && !eventTrigger) {
      return;
    }
    try {
      let trigger: any;
      if (eventTrigger) {
        trigger = {
          name: eventTrigger?.name,
          //TODO: Should handle case when no processID (new Process)
          processId: entityData?.processId,
          triggerType: TriggerType.EVENT,
          triggerData: {
            description: eventTrigger?.description,
          },
        };
      } else if (calendarTrigger) {
        trigger = {
          name: calendarTrigger?.name,
          //TODO: Should handle case when no processID (new Process)
          processId: entityData?.processId,
          triggerType: TriggerType.CALENDAR,
          triggerData: {
            description: calendarTrigger?.description,
            startTime:
              DateTime.fromISO(calendarTrigger?.startTime || '').toISO({ includeOffset: false }) ||
              DateTime.now().toISO({ includeOffset: false }),
            endTime:
              DateTime.fromISO(calendarTrigger?.endTime || '').toISO({ includeOffset: false }) ||
              DateTime.now().plus({ minutes: 30 })?.toISO({ includeOffset: false }),
            timezone: calendarTrigger?.timezone,
            recurrence: calendarTrigger.rrule,
            attendees: calendarTrigger?.guests
              ? calendarTrigger?.guests.split(',').map((guest) => ({
                  email: guest.trim(),
                }))
              : [],
            calendarId: initialData?.service?.calendar?.id || '',
          },
        };
      }
      const query = isEditing ? TriggersGraphQL.mutations.updateTrigger : TriggersGraphQL.mutations.createTrigger;
      if (isEditing && trigger) {
        trigger.triggerId = isEditing;
      }
      const data: createOrUpdateTriggerRequest = await graphQlClient.request(query, {
        trigger,
      });
      if (data?.createTrigger?.triggerId) {
        setTriggers([...triggers, data.createTrigger]);
      }
      if (data?.updateTrigger?.triggerId) {
        setTriggers([
          ...triggers.filter((item) => item.triggerId !== data?.updateTrigger?.triggerId),
          data.updateTrigger,
        ]);
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setIsEditing(null);
      setTriggerSaving(false);
    }
  };

  const handleRemoveTrigger = async (id: string) => {
    setTriggerSaving(true);
    try {
      const deleteResult: deleteTriggerRequest = await graphQlClient.request(TriggersGraphQL.mutations.deleteTrigger, {
        id,
      });
      if (deleteResult?.deleteTrigger?.affected > 0) {
        setTriggers(triggers.filter((trigger: Trigger) => trigger.triggerId !== id));
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setTriggerSaving(false);
    }
  };

  const subDrawerClose = () => {
    setAddTrigger(null);
  };

  const addNewTrigger = (type: TriggerType) => {
    if (!initialData?.processId) {
      dispatch(
        appendActionMessage({
          message: localeProcessForm['saveBeforeTriggers'],
          type: SnackType.WARNING,
        }),
      );
      return;
    }
    setAddTrigger(type);
  };

  const calendarTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.CALENDAR)
    .map((item) => (
      <WolfTriggerBox
        key={item.triggerId}
        title={item.name}
        dateFrom={
          new Date(item.triggerData?.startTime).toLocaleDateString() +
          ' ' +
          new Date(item.triggerData?.startTime).toLocaleTimeString()
        }
        dateTo={
          new Date(item.triggerData?.endTime).toLocaleDateString() +
          ' ' +
          new Date(item.triggerData?.endTime).toLocaleTimeString()
        }
        description={item.triggerData?.description}
        recurrence={item.triggerData?.recurrence}
        onEdit={() => {
          setIsEditing(item.triggerId);
          setSelectedCalendarTrigger(item);
          setAddTrigger(TriggerType.CALENDAR);
        }}
        onDelete={() => handleRemoveTrigger(item.triggerId)}
      />
    ));

  const eventTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.EVENT)
    .map((item) => (
      <WolfTriggerBox
        key={item.triggerId}
        title={item.name}
        description={item.triggerData?.description}
        onEdit={() => {
          setIsEditing(item.triggerId);
          setSelectedEventTrigger(item);
          setAddTrigger(TriggerType.EVENT);
        }}
        onDelete={() => handleRemoveTrigger(item.triggerId)}
      />
    ));

  const triggeredFrom = initialData?.triggeredBy?.map((item) => (
    <WolfTriggerBox
      key={item.process?.processId}
      title={item.process?.name || ''}
      description={''}
      slim={true}
      onEdit={() => {}}
      onDelete={() => {}}
    />
  ));

  if (triggerSaving) {
    return (
      <Box className="flex justify-center items-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="w-full h-full">
      <Prompt when={hasUnsavedChanges} message={localeCommon['changesNotSaved']} />
      <List>
        {initialData?.processId && (
          <Box className="flex justify-between gap-8">
            <Box className="w-full">
              <Box className="flex items-center border-solid border-l-0 border-r-0 border-t-0 border-primaryFrozen justify-between pb-4 w-full">
                <Box className="flex items-center">
                  <Icon color="primary">
                    <CalendarTriggerIcon20 />
                  </Icon>
                  <Typography variant="body14semibold">{localeProcessForm['calTrig']}</Typography>
                </Box>
                <WolfButton
                  onClick={() => addNewTrigger(TriggerType.CALENDAR)}
                  endIcon={<AddOutlined />}
                  variant="outlined"
                  color="tertiary"
                  className="!border-frozenGrayShades2 border-2">
                  {localeCommon['add']}
                </WolfButton>
              </Box>
              {calendarTriggerList}
            </Box>
            <Box className="w-full">
              <Box className="flex items-center border-solid border-l-0 border-r-0 border-t-0 border-primaryFrozen justify-between pb-4 w-full">
                <Box className="flex items-center">
                  <Icon color="primary">
                    <ElectricBoltOutlined />
                  </Icon>
                  <Typography variant="body14semibold">{localeProcessForm['evTrig']}</Typography>
                </Box>
                <WolfButton
                  onClick={() => addNewTrigger(TriggerType.EVENT)}
                  endIcon={<AddOutlined />}
                  variant="outlined"
                  color="tertiary"
                  className="!border-frozenGrayShades2 border-2">
                  {localeCommon['add']}
                </WolfButton>
              </Box>
              {eventTriggerList}
            </Box>
          </Box>
        )}
      </List>
      <Box className="absolute border-solid border-l-0 border-r-0 border-b-0 border-primaryFrozen !h-16 bottom-0 left-0 w-full">
        {/* NOTE: border-l-0 border-r-0 border-b-0 is a workaround to avoid a conflict between mui material and tailwind */}
        <Box
          className="mt-auto bg-white flex justify-between items-center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}>
          <WolfButton variant="outlined" color="secondary" onClick={_onClose}>
            {localeCommon['close']}
          </WolfButton>
        </Box>
      </Box>

      <CustomDrawer
        showBack={true}
        showClose={false}
        title={localeProcessForm['evTrig']}
        isOpen={addTrigger === TriggerType.EVENT}
        onClose={subDrawerClose}
        size="normal">
        <AddEventTriggerForm
          processName={entityData?.name || ''}
          onClose={subDrawerClose}
          onSave={handleAddTrigger}
          initialData={selectedEventTrigger}
          saving={triggerSaving}
        />
      </CustomDrawer>

      <CustomDrawer
        showBack={true}
        showClose={false}
        title={localeProcessForm['calTrig']}
        isOpen={addTrigger === TriggerType.CALENDAR}
        onClose={subDrawerClose}
        size="normal">
        <AddCalendarTriggerForm
          processName={entityData?.name || ''}
          onClose={subDrawerClose}
          onSave={handleAddTrigger}
          initialData={selectedCalendarTrigger}
          saving={triggerSaving}
        />
      </CustomDrawer>

      {showConfirmLeaveModal && (
        <CustomModal
          isOpen={!!showConfirmLeaveModal}
          title={localeCommon['confirmLeave']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeConfirmLeaveModal}
          onConfirm={closeAndConfirmLeaveModal}>
          <ConfirmLeaveModal />
        </CustomModal>
      )}
    </Box>
  );
};

export default AddTriggerForm;
