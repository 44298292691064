import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';

interface WolfDropdownProps {
  label: string;
  items: { icon?: React.ReactNode; endingIcon?: React.ReactNode; text: string; disabled?: boolean; value: string }[];
  showBorder?: boolean;
  initialSelectedItems?: string[];
  singleSelect?: boolean;
  showClearButton?: boolean; // New flag for showing/hiding the clear button
  onChange: (selectedItems: DropdownSelectedItem[]) => void;
}

export interface DropdownSelectedItem {
  icon?: React.ReactNode;
  endingIcon?: React.ReactNode;
  text: string;
  disabled?: boolean;
  value: string;
}

const StyledMenu = styled(Menu)(({ theme }: { theme: any }) => ({
  '& .MuiPaper-root': {
    minWidth: '200px',
    maxHeight: '300px',
    boxShadow: theme.shadows[1],
    border: 0,
  },
  '& .MuiMenuItem-root': {
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.frozenGrayShades2.main,
    },
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiListItemText-root': {
    fontSize: '16px',
  },
}));

const WolfDropdown: React.FC<WolfDropdownProps> = ({
  label,
  items,
  showBorder = false,
  singleSelect = false,
  initialSelectedItems = [],
  showClearButton = false, // Default value for showClearButton is false
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<DropdownSelectedItem[]>(
    items.filter((item: DropdownSelectedItem) => initialSelectedItems.includes(item.value)),
  );

  useEffect(() => {
    setSelectedItems(items.filter((item: DropdownSelectedItem) => initialSelectedItems.includes(item.value)));
  }, [initialSelectedItems]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: {
    icon?: React.ReactNode;
    endingIcon?: React.ReactNode;
    text: string;
    disabled?: boolean;
    value: string;
  }) => {
    if (singleSelect) {
      setSelectedItems([item]);
      onChange([item]);
      handleClose();
      return;
    }
    const isSelected = selectedItems.some((el) => el.value === item.value);

    const updatedSelectedItems = isSelected
      ? selectedItems.filter((el) => el.value !== item.value)
      : [...selectedItems, item];

    onChange(updatedSelectedItems);
    setSelectedItems(updatedSelectedItems);
    handleClose();
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
    onChange([]);
  };

  return (
    <Box className="w-full">
      <Button
        variant="outlined"
        className="!bg-white !justify-between !text-body16 !w-full !border-frozenGrayShades2"
        sx={{ textTransform: 'none', border: showBorder ? 2 : 0 }}
        endIcon={
          <>
            {showClearButton && selectedItems.length > 0 && (
              <Box className="!p-0 text-additionalBorder w-6 h-6" onClick={handleClearSelection}>
                <CloseIcon />
              </Box>
            )}
            <ArrowDropDownIcon />
          </>
        }
        onClick={handleClick}>
        {label || ' '}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => handleMenuItemClick(item)}
            selected={selectedItems.some((selectedItem) => selectedItem.value === item.value)}>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.text} disableTypography={true} />
            {item.endingIcon && <ListItemIcon>{item.endingIcon}</ListItemIcon>}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default WolfDropdown;
