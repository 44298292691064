import { Step } from '@models/step.model';

// Return every step that is in the ids array sorted by order
export const getStepsInsideSwitchRepeat = (ids: string, stepsList: Step[] | undefined) => {
  if (!ids || !stepsList) {
    return [];
  }
  try {
    let auxSteps = ids
      ?.split(',')
      .map((item: string) => {
        return stepsList.find((step: Step) => step?.stepId === item);
      })
      .filter((step): step is Step => !!step);
    return auxSteps.sort((a: Step, b: Step) => a?.order - b?.order);
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
