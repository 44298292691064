import WolfButton from '@components/ui/WolfButton';
import WolfColorSelector from '@components/ui/WolfColorSelector';
import WolfDropdown from '@components/ui/WolfDropdown';
import { AreaIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import WolfInput from '@components/ui/WolfInput';
import WolfTextarea from '@components/ui/WolfTextarea';
import WolfTooltip from '@components/ui/WolfTooltip';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasNameIdColorWServicesRequest } from '@models/area.model';
import { EntityData, SnackType } from '@models/common.model';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, List, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import generateColorVariations from '@utils/generateColorVariations';
import { AREA_COLORS } from '@utils/random.color';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface AddItemFormProps {
  onClose: () => void;
  handleSave: (entity: EntityData) => void;
  handleDelete: (id: string) => void;
  drawerType: 'add' | 'edit';
  initialData: EntityData;
  parentColor?: string;
  existingColors?: string[];
  offerAreaColors?: boolean;
  saving: boolean;
  hasSelectedArea?: boolean;
  isService?: boolean;
  saveBtnText?: string;
}

const AddItemForm: React.FC<AddItemFormProps> = ({
  onClose,
  handleSave,
  handleDelete,
  drawerType,
  initialData,
  parentColor,
  existingColors,
  offerAreaColors = false,
  saving = false,
  hasSelectedArea = false,
  isService = false,
  saveBtnText,
}) => {
  const locale = useTranslation('common');
  const localeService = useTranslation('services');

  const [entityData, setEntityData] = useState<EntityData>({
    name: '',
    description: '',
    color: '',
    areaId: '',
    serviceId: '',
  });

  const [colorList, setColorList] = useState<string[]>([]);
  const [areas, setAreas] = useState<
    { text: string; value: string; color: string; services: { name: string; color: string }[] }[]
  >([]);
  const [selectedArea, setSelectedArea] = useState<{
    text: string;
    value: string;
    color: string;
    services: { name: string; color: string }[];
  }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (drawerType === 'edit' && initialData) {
      setEntityData(initialData);
    }
  }, [initialData, drawerType]);

  useEffect(() => {
    if (drawerType === 'add' && !hasSelectedArea) {
      getAreasNameId();
    }
  }, [drawerType, hasSelectedArea]);

  useEffect(() => {
    if (saving) return;

    if (parentColor && drawerType === 'add') {
      const newColors = generateColorVariations(parentColor, existingColors || []);
      setColorList(newColors);
      handleColorChange(newColors[0]);
    }
    if (offerAreaColors && drawerType === 'add') {
      const notUsedColors = AREA_COLORS.filter((item) => !existingColors?.includes(item));
      setColorList(notUsedColors);
      handleColorChange(notUsedColors[0]);
    }
    if (drawerType === 'edit' && isService) {
      const existingColorsWithInitialData: string[] = [initialData.color || '', ...(existingColors || [])];
      const newColors = generateColorVariations(parentColor || '', existingColorsWithInitialData);
      setColorList([initialData.color || '', ...newColors]);
      handleColorChange(initialData.color || '');
    }

    if (drawerType === 'edit' && !isService) {
      const notUsedColors = AREA_COLORS.filter((item) => !existingColors?.includes(item) && item !== initialData.color);
      setColorList([initialData.color || '', ...notUsedColors]);
      handleColorChange(initialData.color || '');
    }
  }, [parentColor, offerAreaColors, existingColors, drawerType, initialData.color, isService]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntityData({
      ...entityData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEntityData({
      ...entityData,
      description: event.target.value,
    });
  };

  const handleColorChange = (color: string) => {
    setEntityData((prev) => ({
      ...prev,
      color,
    }));
  };

  const handleSelectArea = (area: any) => {
    const auxArea = area as {
      text: string;
      value: string;
      color: string;
      services: { name: string; color: string }[];
    };
    setSelectedArea(auxArea);
    const existingColors = auxArea.services.map((item) => item.color);
    const newColors = generateColorVariations(auxArea.color, existingColors || []);
    setColorList(newColors);
    setEntityData({
      ...entityData,
      areaId: auxArea.value,
      color: newColors[0],
    });
  };

  const getAreasNameId = async () => {
    try {
      const data: getAreasNameIdColorWServicesRequest = await graphQlClient.request(
        AreasGraphQL.queries.getAreasNameIdColorWServices,
      );
      setAreas(
        data.getAreas
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((area) => ({
            text: area.name,
            value: area.areaId,
            color: area.color,
            services: area.services,
          })),
      );
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || locale['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  return (
    <Box className="w-full h-full">
      <List className="flex-1 p-0">
        {isService && !hasSelectedArea && drawerType === 'add' && (
          <Box className="pb-8">
            <Box className="flex items-center justify-between w-full">
              <Typography variant="body14semibold" className="w-full">
                {localeService['areaForService'] + ' *'}
              </Typography>
              <Typography variant="body14" className="w-full text-right text-additionalPlaceholder">
                {locale['required']}
              </Typography>
            </Box>
            <Box className="border-2 border-primaryIcy border-solid rounded">
              <WolfDropdown
                label={selectedArea?.text || localeService['selectArea']}
                items={areas}
                singleSelect={true}
                onChange={(item) => handleSelectArea(item[0])}
              />
            </Box>
          </Box>
        )}
        <Box className="pb-8">
          <WolfInput
            fullWidth
            label={locale['name'] + ' *'}
            secondaryLabel={locale['required']}
            name="name"
            autoComplete="off"
            value={entityData.name}
            onChange={handleInputChange}
            required
          />
        </Box>

        <Box className="pb-8">
          <WolfTextarea
            fullWidth
            label={locale['description'] + ' *'}
            secondaryLabel={locale['required']}
            placeholder={locale['description']}
            value={entityData.description}
            onChange={handleTextAreaChange}
            detailTextWidth={1000}
            required
          />
        </Box>

        <Box className="flex flex-wrap gap-4">
          <Box className="flex items-center justify-between w-full">
            <Typography variant="body14semibold" className="w-full">
              {locale['color'] + ' * '}
              <WolfTooltip
                title={
                  isService && !selectedArea
                    ? localeService['pickAreaToSeeColors']
                    : isService
                      ? localeService['selectAColorService']
                      : localeService['selectAColorArea']
                }>
                <IconButton size="small">
                  <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
                </IconButton>
              </WolfTooltip>
            </Typography>
            <Typography variant="body14" className="w-full text-right text-additionalPlaceholder">
              {locale['required']}
            </Typography>
          </Box>

          {colorList.map((color) => (
            <Box key={color} className="mx-2">
              <WolfColorSelector
                selected={entityData.color === color}
                icon={isService ? <ServicesIcon24 /> : <AreaIcon24 />}
                color={color}
                onClick={handleColorChange}
              />
            </Box>
          ))}
        </Box>
      </List>

      {/* // TODO: Make this reusable (may not be worth it?) */}
      {/* Footer with Cancel and Save Buttons */}
      {/* NOTE: border-l-0 border-r-0 border-b-0 is a workaround to avoid a conflict between mui material and tailwind */}
      <Box className="absolute border-solid border-l-0 border-r-0 border-b-0 border-primaryFrozen !h-16 bottom-0 left-0 w-full">
        <Box
          className="mt-auto bg-white flex justify-between items-center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}>
          <WolfButton variant="outlined" color="secondary" onClick={onClose}>
            {locale['cancel']}
          </WolfButton>
          {drawerType === 'edit' && (
            <WolfButton
              color="error"
              variant="outlined"
              onClick={() => {
                handleDelete((isService ? entityData.serviceId : entityData.areaId) || '');
              }}>
              {locale['delete']}
            </WolfButton>
          )}
          <WolfButton variant="contained" color="primary" disabled={saving} onClick={() => handleSave(entityData)}>
            {saveBtnText || locale['save']}
          </WolfButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddItemForm;
