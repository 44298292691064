import { OnboardingDetails } from '@models/organization.model';

export const OrganizationTypes = {
  SET_ORGANIZATION_DATA: 'SET_ORGANIZATION_DATA',
};

export const setOrganizationData = (data: {
  organizationId: string;
  name: string;
  description?: string;
  context?: any;
  lastProfileUpdate?: Date;
  pendings?: any;
}) => {
  return {
    type: OrganizationTypes.SET_ORGANIZATION_DATA,
    data: data,
  };
};

export type OrganizationState = {
  organizationId: string | null;
  name: string | null;
  description: string | null | undefined;
  context: any | null | undefined;
  lastProfileUpdate: string | null | undefined;
  onboardingDetails: OnboardingDetails | null | undefined;
  pendings: any | null | undefined;
};

// INITIAL STATE
const organizationInitialState: OrganizationState = {
  organizationId: null,
  name: null,
  description: null,
  context: null,
  lastProfileUpdate: null,
  onboardingDetails: null,
  pendings: null,
};

export type OrganizationAction = {
  type: string;
  data: {
    organizationId: string;
    name: string;
    description?: string;
    context?: any;
    lastProfileUpdate?: string;
    onboardingDetails?: OnboardingDetails;
    pendings?: any;
  };
};

const organizationReducer = (state = organizationInitialState, action: OrganizationAction) => {
  switch (action.type) {
    case OrganizationTypes.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationId: action.data.organizationId,
        name: action.data.name,
        description: action.data?.description,
        context: action.data?.context,
        lastProfileUpdate: action.data?.lastProfileUpdate,
        onboardingDetails: action.data?.onboardingDetails,
      };

    default:
      return state;
  }
};

export default organizationReducer;
