import WolfCard from '@components/ui/WolfCard';
import { AreaIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import { useTranslation } from '@hooks/useTranslation';
import { Area } from '@models/area.model';
import { Process } from '@models/process.model';
import { Service } from '@models/service.model';
import { TriggerType } from '@models/trigger.model';
import {
  CalendarMonthSharp,
  CheckCircleOutline,
  ElectricBoltOutlined,
  GroupWorkOutlined,
  PolicyOutlined,
} from '@mui/icons-material';
import { Box, Fade, Grid } from '@mui/material';
import { confirmWolfieElement } from '@redux/reducers/wolfie.reducer';
import getCategoriesNames from '@utils/getCategoriesNames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WolfTooltip from './ui/WolfTooltip';

interface ItemWithSummaryDragProps {
  elements: Service[] | Area[] | Process[];
  isGridView?: boolean;
  isSidebarOpen?: boolean;
  elementIdName: 'serviceId' | 'areaId' | 'processId';
  hasCalendar: boolean;
  hasStorage: boolean;
  showDrag: boolean;
  showEdit?: boolean;
  droppableId: string;
  handleDragEnd: (result: DropResult) => void;
  handleTitleClick?: (id: string) => void;
  handleCardClick: (id: string) => void;
  drawerEditOpen: (id: string) => void;
  cardsRefs?: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
}

const ItemWithSummaryDrag: React.FC<ItemWithSummaryDragProps> = ({
  elements,
  isGridView = false,
  isSidebarOpen = false,
  hasCalendar,
  hasStorage,
  showDrag,
  elementIdName,
  droppableId,
  handleDragEnd,
  handleCardClick,
  drawerEditOpen,
  cardsRefs,
}) => {
  const localeCommon = useTranslation('common');
  const localeSummaryCard = useTranslation('summaryCard');
  const dispatch = useDispatch();
  const history = useHistory();

  const editAction = (e: any, id: string) => {
    e.stopPropagation();
    if (elementIdName === 'processId') {
      history.push(`/processes/${id}`);
    } else {
      drawerEditOpen(id);
    }
  };

  const handleWolfieClick = (id: string) => {
    dispatch(confirmWolfieElement(id));
  };

  const _handleBadgeClick = (e: any, badge: string, element: any, elementIdName: string) => {
    e.stopPropagation();
    if (elementIdName === 'serviceId') {
      const auxId = (element as Service).serviceId;
      history.push(`/processes?serviceId=${auxId}&category=${badge}`);
    }
    if (elementIdName === 'areaId') {
      const auxId = (element as Area).services?.find((service) => service.name === badge)?.serviceId;
      history.push(`/processes?serviceId=${auxId}`);
    }
  };

  const handleMapClick = (e: any, element: Service | Area | Process, elementIdName: string) => {
    e.stopPropagation();
    if (elementIdName === 'processId') {
      history.push(`/processMap?processId=${(element as Process).processId}`);
    }
    if (elementIdName === 'serviceId') {
      history.push(`/processMap?serviceId=${(element as Service).serviceId}`);
    }
    if (elementIdName === 'areaId') {
      history.push(`/processMap?areaId=${(element as Area).areaId}`);
    }
  };

  const getBadges = (element: Service | Area | Process) => {
    if (elementIdName === 'serviceId') {
      return getCategoriesNames(element as Service);
    } else if (elementIdName === 'processId') {
      return [];
      // return (element as Process).policies?.map((policy) => policy.name) || [];
    } else {
      return (element as Area).services?.map((service) => service.name) || [];
    }
  };

  const getSummaryCounts = (
    element: Service | Area | Process,
  ): { icon: React.ReactNode; tooltip: string; count: number }[] => {
    if (elementIdName === 'processId') {
      return [
        {
          icon: <ElectricBoltOutlined />,
          tooltip: localeSummaryCard['numberEventTriggers'],
          count:
            (element as Process).triggers?.filter((trigger) => trigger.triggerType === TriggerType.EVENT).length || 0,
        },
        {
          icon: <CalendarMonthSharp />,
          tooltip: localeSummaryCard['numberCalendarTriggers'],
          count:
            (element as Process).triggers?.filter((trigger) => trigger.triggerType === TriggerType.CALENDAR).length ||
            0,
        },
        {
          icon: <PolicyOutlined />,
          tooltip: localeSummaryCard['numberPolicies'],
          count: (element as Process).policies?.length || 0,
        },
      ];
    } else {
      return [];
    }
  };

  const getheaderIcon = (element: Service | Area | Process) => {
    if (elementIdName === 'areaId') {
      return <AreaIcon24 />;
    } else if (elementIdName === 'serviceId') {
      return <ServicesIcon24 />;
    } else if (elementIdName === 'processId') {
      return <GroupWorkOutlined />;
    } else {
      return <ServicesIcon24 />;
    }
  };

  const getEmptyMessage = (element: Service | Area | Process) => {
    if (elementIdName === 'processId') {
      if ((element as Process).steps?.length === 0 || !(element as Process).steps) {
        return localeSummaryCard['emptyProcess'];
      } else {
        return '';
      }
    } else if (elementIdName === 'serviceId') {
      if ((element as Service).processes?.length === 0 || !(element as Service).processes) {
        return localeSummaryCard['emptyService'];
      } else {
        return '';
      }
    } else if (elementIdName === 'areaId') {
      if ((element as Area).services?.length === 0 || !(element as Area).services) {
        return localeSummaryCard['emptyArea'];
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div className="flex flex-wrap justify-center w-full" ref={provided.innerRef} {...provided.droppableProps}>
            <Grid container spacing={3} alignItems={'stretch'}>
              {elements.map((element: any, index: number) => {
                return (
                  <Grid
                    key={element[elementIdName]}
                    item
                    xs={12}
                    md={isGridView ? (isSidebarOpen ? 12 : 6) : 12}
                    lg={isGridView ? (isSidebarOpen ? 6 : 4) : 12}
                    xl={elementIdName === 'areaId' ? (isGridView ? 3 : 12) : isGridView ? 4 : 12}>
                    <Draggable
                      key={element[elementIdName]}
                      draggableId={element[elementIdName]}
                      index={index}
                      isDragDisabled={!showDrag}>
                      {(provided) => (
                        <Fade in={true} timeout={element?.isWolfieGenerated ? 700 : 0}>
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={'w-full relative flex justify-center '}
                            width="100%">
                            {/* // We scroll to the box above the card, to enable having it conditionally and to avoid passing down the prop inside wolfcard */}
                            {cardsRefs && (
                              <Box
                                sx={{ scrollMargin: 100 }}
                                ref={(el: HTMLDivElement) => (cardsRefs.current[element[elementIdName]] = el)}></Box>
                            )}

                            <WolfCard
                              key={element[elementIdName]}
                              color={element.color || element?.service?.color}
                              name={element.name}
                              description={elementIdName === 'processId' ? '' : element.description}
                              goal={element.goal || ''}
                              showCalendar={hasCalendar}
                              showStorage={hasStorage}
                              storageLocation={element.storageLocation}
                              showDocument={elementIdName === 'processId' && !!element?.location}
                              documentLocation={element?.location}
                              badges={getBadges(element)}
                              handleBadgeClick={(e: any, badge: string) =>
                                _handleBadgeClick(e, badge, element, elementIdName)
                              }
                              summaryCounts={getSummaryCounts(element)}
                              headerIcon={getheaderIcon(element)}
                              showEditButton={!element?.isWolfieGenerated}
                              showDragIcon={showDrag}
                              isWolfieGenerated={element?.isWolfieGenerated || null}
                              editAction={(e) => editAction(e, element[elementIdName])}
                              cardClickAction={() => handleCardClick && handleCardClick(element[elementIdName])}
                              emptyMessage={getEmptyMessage(element)}
                              handleMapClick={(e: any) => handleMapClick(e, element, elementIdName)}
                            />
                            {element?.isWolfieGenerated && (
                              <Box
                                className="absolute top-3 -right-5 bg-feedbackSuccess text-white p-1 rounded-full w-8 h-8 opacity-100"
                                onClick={() => handleWolfieClick(element[elementIdName])}>
                                <WolfTooltip title={localeCommon['wolfieGenerated']}>
                                  <span>
                                    {/* Span Required by tooltip to work */}
                                    <CheckCircleOutline />
                                  </span>
                                </WolfTooltip>
                              </Box>
                            )}
                          </Box>
                        </Fade>
                      )}
                    </Draggable>
                  </Grid>
                );
              })}
            </Grid>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ItemWithSummaryDrag;
