import WolfButton from '@components/ui/WolfButton';
import { TrashIcon24 } from '@components/ui/WolfIcons';
import WolfInput from '@components/ui/WolfInput';
import WolfSectionColor from '@components/ui/WolfSectionColor';
import WolfTooltip from '@components/ui/WolfTooltip';
import { graphQlClient } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import {
  createOrUpdateOrganizationRequest,
  getOrganizationByIdRequest,
  OnboardingDetails,
  Organization,
  OrganizationContext,
  updatePendingActionStatusRequest,
} from '@models/organization.model';
import { UserRoles } from '@models/user.model';
import { WolfieStatus } from '@models/wolfie.model';
import { CancelOutlined, CheckOutlined, CorporateFareOutlined, Edit } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton, styled, Switch, Typography, useTheme } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import {
  disableWolfie,
  setWolfieContextInfo,
  setWolfieEnabled,
  setWolfieMinimized,
  setWolfieStatus,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'border 0.3s',
  maxWidth: '684px',
  border: '1px solid ' + theme.palette.primaryFrozen.main,
  boxShadow: 'none',
  '&:hover': {
    border: `2px solid ${theme.palette.primaryIcy.main}`,
    boxShadow: 'none',
    '& .drag-icon': {
      display: 'inline-flex',
    },
  },
}));

const OrganizationDescription: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { role } = useSelector((state: RootState) => state.user);
  const {
    name: organizationName,
    description: organizationDescription,
    context: organizationContext,
  } = useSelector((state: RootState) => state.organization);
  const {
    enabled: enabledWolfie,
    status: wolfieStatus,
    data: wolfieData,
  } = useSelector((state: RootState) => state.wolfie);
  const [currentOrganizationData, setCurrentOrganizationData] = useState<Organization | null>(null);
  const [editableContext, setEditableContext] = useState<OrganizationContext | null>(null);
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});
  const theme = useTheme();
  const localeCommon = useTranslation('common');
  const localeMenu = useTranslation('menu');
  const localeOrganizations = useTranslation('organizations');
  const localeActionMessages = useTranslation('actionMessages');
  const dispatch = useDispatch();

  const onboardingTasks = [
    { order: 1, label: localeMenu['ftueCompleted'], name: 'ftueCompleted' },
    { order: 2, label: localeMenu['areaCreated'], name: 'areaCreated' },
    { order: 3, label: localeMenu['serviceCreated'], name: 'serviceCreated' },
    { order: 4, label: localeMenu['processCreated'], name: 'processCreated' },
    { order: 5, label: localeMenu['processWritten'], name: 'processWritten' },
  ];

  useEffect(() => {
    if (organizationId) {
      getOrganizationData(organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    if (enabledWolfie && wolfieStatus === WolfieStatus.DONE && wolfieData) {
      updateOrganizationContext(wolfieData?.mainAreas, wolfieData?.troublesomeProcesses, wolfieData?.description);
    }
  }, [enabledWolfie, wolfieStatus, wolfieData]);

  const getOrganizationData = async (id: string) => {
    const variables = { id };
    const userData: getOrganizationByIdRequest = await graphQlClient.request(
      OrganizationsGraphQL.queries.getOrganizationById,
      variables,
    );
    setCurrentOrganizationData(userData.getOrganizationById);
    if (userData.getOrganizationById.context) {
      setEditableContext(userData.getOrganizationById.context);
    }
  };

  const toggleEdit = (key: string, index: number) => {
    setIsEditing((prev) => ({ [`${key}-${index}`]: !prev[`${key}-${index}`] }));
  };

  const handleInputChange = (event: any) => {
    if (!event.target.name) return;
    const [key, index] = event.target.name.split('-');
    if (editableContext) {
      const updatedItems = { ...editableContext };
      if (updatedItems[key as keyof OrganizationContext]) {
        const itemArray = updatedItems[key as keyof OrganizationContext];
        if (Array.isArray(itemArray)) {
          itemArray[index] = event.target.value;
          updatedItems[key as keyof OrganizationContext] = itemArray;
        }
      }
      setEditableContext(updatedItems);
    }
  };

  const handleToggleChange = (key: keyof OnboardingDetails, value: boolean) => {
    if (currentOrganizationData) {
      setCurrentOrganizationData({
        ...currentOrganizationData,
        onboardingDetails: {
          ...currentOrganizationData.onboardingDetails,
          [key]: value,
        },
      });
    }
  };

  const handleSkippedDateChange = (event: any) => {
    if (currentOrganizationData) {
      setCurrentOrganizationData({
        ...currentOrganizationData,
        onboardingDetails: {
          ...currentOrganizationData.onboardingDetails,
          skippedUntil: new Date(event.target.value).toISOString(),
        },
      });
    }
  };

  const updateOrganization = async () => {
    try {
      const variables = {
        organization: {
          context: editableContext,
          organizationId: organizationId || '',
          lastProfileUpdate: new Date().toISOString(),
          onboardingDetails: {
            ...currentOrganizationData?.onboardingDetails,
          },
        },
      };
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        variables,
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(
          appendActionMessage({
            message: localeActionMessages['changesSavedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getOrganizationData(organizationId || '');
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const updateOrganizationContext = async (
    mainAreas: string[],
    troublesomeProcesses: string[],
    description: string,
  ) => {
    try {
      const variables = {
        organization: {
          context: {
            mainAreas,
            troublesomeProcesses,
          },
          description,
          organizationId: organizationId || '',
          lastProfileUpdate: new Date().toISOString(),
        },
      };
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        variables,
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(
          appendActionMessage({
            message: localeActionMessages['changesSavedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        dispatch(disableWolfie());
        getOrganizationData(organizationId || '');
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleSaveChanges = async () => {
    setIsEditing({});
    await updateOrganization();
  };

  const handleDiscardChanges = () => {
    setIsEditing({});
    // Reset to original data
    if (organizationId) {
      getOrganizationData(organizationId);
    }
  };

  const handleRedoFTUE = () => {
    dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
    dispatch(setWolfieEnabled(true));
    dispatch(
      setWolfieContextInfo({
        name: organizationName || '',
        description: organizationDescription || '',
        orgContext: { ...organizationContext } || null,
      }),
    );
    dispatch(setWolfieMinimized(false));
  };

  const formattedLastProfileUpdate = useMemo(() => {
    if (currentOrganizationData?.lastProfileUpdate) {
      return new Date(currentOrganizationData.lastProfileUpdate).toLocaleDateString();
    }
    return '';
  }, [currentOrganizationData?.lastProfileUpdate]);

  if (!currentOrganizationData) {
    return null;
  }

  const handleCompleteAction = async (actionId: string) => {
    try {
      const variables = {
        organizationId: organizationId,
        actionId: actionId,
        status: 'COMPLETED',
      };

      const data: updatePendingActionStatusRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updatePendingActionStatus,
        variables,
      );

      if (data?.updatePendingActionStatus?.organizationId) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestSuccess'],
            type: SnackType.SUCCESS,
          }),
        );
        // Refresh organization data to update the pending actions list
        getOrganizationData(organizationId || '');
      }
    } catch (error) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getActionTypeText = (type: string) => {
    switch (type) {
      case 'PROCESS_CREATION':
        return localeOrganizations['processCreation'];
      case 'STEP_CREATION':
        return localeOrganizations['stepCreation'];
      default:
        return '';
    }
  };

  return (
    <Box className="flex flex-wrap justify-center h-fit mx-auto mt-20">
      <Typography variant="h2" className="w-full pb-6 text-center">
        {currentOrganizationData?.name}
      </Typography>

      <StyledCard className={'w-full relative flex flex-wrap '}>
        <CardContent className="flex flex-wrap h-full w-full">
          <Box mb={2} className="w-full">
            <Box className="flex justify-between">
              <WolfSectionColor color={theme.palette.backgroundBlue.main} icon={<CorporateFareOutlined />} />
              <Typography variant="body12" component="div" mb={2}>
                {localeOrganizations['lastProfileUpdate']} {formattedLastProfileUpdate}
              </Typography>
            </Box>
            <Typography variant="body16semibold" component="div" mb={2}>
              {currentOrganizationData?.name}
            </Typography>
            <Box className="pt-6">
              <Box>
                <Typography variant="body14semibold" color="primary" mb={2}>
                  {localeCommon['owners']}
                </Typography>
              </Box>
              <Typography variant="body14" color="primary" mb={2}>
                {currentOrganizationData?.ownersInfo}
              </Typography>
            </Box>

            {/* Main Areas */}
            {currentOrganizationData?.context?.mainAreas && (
              <Box className="pt-6">
                <Typography variant="h3" color="primary" mb={2}>
                  {localeOrganizations['interviewProfile']}
                </Typography>
                <Typography variant="body14semibold" color="primary" mb={2}>
                  {localeOrganizations['mainAreas']}
                </Typography>
                <Box>
                  {currentOrganizationData?.context?.mainAreas?.map((area, index) => (
                    <Box key={`mainArea-${index}`} className="flex justify-between py-2 items-center">
                      {isEditing[`mainAreas-${index}`] ? (
                        <Box className="gap-3 flex items-center justify-between w-full">
                          <WolfInput
                            fullWidth
                            value={area}
                            label={''}
                            name={`mainAreas-${index}`}
                            onChange={handleInputChange}
                            onBlur={() => toggleEdit('mainAreas', index)}
                            variant="outlined"
                            size="small"
                          />
                          <Box className="flex gap-3">
                            <WolfButton variant="contained" color="primary" onClick={handleDiscardChanges}>
                              <CancelOutlined fontSize="small" />
                            </WolfButton>
                            <WolfButton variant="contained" color="primary" onClick={handleSaveChanges}>
                              <CheckOutlined fontSize="small" />
                            </WolfButton>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="body14">{area}</Typography>
                          <IconButton onClick={() => toggleEdit('mainAreas', index)}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {/* Troublesome Processes */}
            {currentOrganizationData?.context?.troublesomeProcesses && (
              <Box className="pt-6">
                <Typography variant="body14semibold" color="primary" mb={2}>
                  {localeOrganizations['troublesomeProcesses']}
                </Typography>
                <Box>
                  {currentOrganizationData?.context?.troublesomeProcesses?.map((process, index) => (
                    <Box key={`process-${index}`} className="flex justify-between py-2 items-center">
                      {isEditing[`troublesomeProcesses-${index}`] ? (
                        <Box className="gap-3 flex items-center justify-between w-full">
                          <WolfInput
                            fullWidth
                            label={''}
                            value={process}
                            name={`troublesomeProcesses-${index}`}
                            onBlur={() => toggleEdit('troublesomeProcesses', index)}
                            variant="outlined"
                            size="small"
                          />
                          <Box className="flex gap-3">
                            <WolfButton variant="contained" color="primary" onClick={handleDiscardChanges}>
                              <CancelOutlined fontSize="small" />
                            </WolfButton>
                            <WolfButton variant="contained" color="primary" onClick={handleSaveChanges}>
                              <CheckOutlined fontSize="small" />
                            </WolfButton>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="body14">{process}</Typography>
                          <IconButton onClick={() => toggleEdit('troublesomeProcesses', index)}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {/* Pending Actions */}
            {currentOrganizationData?.pendings && currentOrganizationData?.pendings?.length > 0 && (
              <Box className="pt-6">
                <Typography variant="body14semibold" color="primary" mb={2}>
                  {localeOrganizations['pendingActions']}
                </Typography>
                <Box>
                  {currentOrganizationData?.pendings
                    ?.filter((action: any) => action.status === 'PENDING')
                    ?.map((action: any, index: any) => (
                      <Box key={`process-${index}`} className="flex justify-between py-2 items-center">
                        <Box className="flex">
                          <Typography variant="body14">
                            {action.type ? getActionTypeText(action.type) + ' - ' : ''}{' '}
                            {action.name ? action.name + ' - ' : ''}
                            {'Date: ' + new Date(action.createdAt).toLocaleDateString() || ''}
                          </Typography>
                        </Box>
                        <IconButton onClick={() => handleCompleteAction(action.id)}>
                          <TrashIcon24 />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}

            {/* Onbaording Status Section with Toggles */}
            {role === UserRoles.SUPER_ADMIN && (
              <Box className="pt-6">
                <Typography variant="h3" color="primary" mb={2}>
                  {localeOrganizations['onboardingStatus']}
                </Typography>
                <Box>
                  {currentOrganizationData?.onboardingDetails && (
                    <>
                      {onboardingTasks.map(({ name, label }) => (
                        <Box key={label} className="flex justify-between py-2">
                          <Typography variant="body14">{label}</Typography>
                          <Switch
                            checked={
                              currentOrganizationData?.onboardingDetails?.[name as keyof OnboardingDetails] as boolean
                            }
                            onChange={(e) => handleToggleChange(name as keyof OnboardingDetails, e.target.checked)}
                          />
                        </Box>
                      ))}
                    </>
                  )}
                  {currentOrganizationData?.onboardingDetails?.skippedUntil && (
                    <Box className="flex justify-between py-2 items-center">
                      <Typography variant="body14" className="text-nowrap md:!mr-32 block">
                        {localeOrganizations['skippedUntil']}
                      </Typography>
                      <WolfInput
                        fullWidth
                        label={''}
                        value={currentOrganizationData?.onboardingDetails?.skippedUntil.split('T')[0]}
                        onChange={handleSkippedDateChange}
                        type="date"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box className="w-full flex justify-end my-3 gap-4">
            <WolfButton variant="outlined" color="secondary" onClick={handleDiscardChanges}>
              {localeCommon['cancel']}
            </WolfButton>
            <WolfButton variant="contained" color="primary" onClick={handleSaveChanges}>
              {localeCommon['save']}
            </WolfButton>
          </Box>
          <Box className="w-full flex justify-end my-3 items-center">
            <WolfTooltip title={localeOrganizations['redoFTUEDescription']}>
              <span>
                <WolfButton variant="contained" color="secondary" onClick={handleRedoFTUE} disabled={enabledWolfie}>
                  {localeOrganizations['redoFTUE']}
                </WolfButton>
              </span>
            </WolfTooltip>
          </Box>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default OrganizationDescription;
