import WolfSectionColor from '@components/ui/WolfSectionColor';
import WolfStepIndicator from '@components/ui/WolfStepIndicator';
import WolfTooltip from '@components/ui/WolfTooltip';
import { NodeData } from '@models/process-map.model';
import { StepType } from '@models/step.model';
import { AddCircleOutline, Cable, ExpandLess, ExpandMore, GroupWorkOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { getStepsInsideSwitchRepeat } from '@utils/getStepsInsideSwitchRepeat';
import { isColorDark } from '@utils/isColorDark';
import { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

interface ProcessNodeProps {
  data: NodeData;
  id: string;
  expand?: boolean;
}

export const ProcessNode = ({ data, id, expand }: ProcessNodeProps) => {
  const dark = isColorDark(data.backgroundColor || '#FFFFFF');
  const [expanded, setExpanded] = useState(!!expand);
  const [showTools, setShowTools] = useState(false);
  const [isDrawn, setIsDrawn] = useState(false);

  useEffect(() => {
    if (data.expand !== undefined) {
      setExpanded(data.expand);
    }
  }, [data.expand]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleShowTools = () => {
    setShowTools(!showTools);
  };

  const toggleIsDrawn = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTimeout(() => {
      setIsDrawn(true);
      setShowTools(true);
    }, 100);
  };

  const toggleShowLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTimeout(() => {
      setShowTools(false);
    }, 2000);
  };

  return (
    <div
      className={
        'relative flex items-center shadow-lg rounded-lg border border-additionalBorder bg-white ' +
        (data.isExternal ? (data.isTrigger ? 'w-96' : 'w-110') : 'w-72')
      }>
      <Box
        onClick={toggleShowTools}
        sx={{ backgroundColor: data.backgroundColor + '55' }}
        className={'p-4 w-full ' + (expanded ? 'h-auto' : 'h-18')}>
        <Handle type="target" id="left" position={Position.Left} />
        <Handle type="target" id="top" position={Position.Top} />
        <Handle type="source" id="right" position={Position.Right} />
        <Handle type="source" id="bottom" position={Position.Bottom} />

        {/* Node Content */}
        <Box className="flex items-center justify-between w-full">
          <Box className="flex items-center">
            <Box className="w-10 h-10 rounded-full flex items-center justify-center mr-2">
              <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color || '#000000'} />
            </Box>
            <Box className="flex flex-wrap items-center">
              {data.preLabel && (
                <Typography
                  className={`w-100 text-xs ${dark ? 'text-white' : 'text-black'}`}
                  component="span"
                  title={data?.preLabel}>
                  {data?.preLabel?.slice(0, 30) + (data?.preLabel?.length > 30 ? '...' : '') + ' - '}
                </Typography>
              )}
              <Typography
                className={`!font-bold ${dark ? 'text-white' : 'text-black'}`}
                component="span"
                data-type="process"
                data-id={id}
                data-origin="process-name"
                title={data.label}>
                {data.label.slice(0, 30) + (data.label.length > 30 ? '...' : '')}
              </Typography>
            </Box>
          </Box>

          {!data.isExternal && (
            // TODO: Localize tooltips
            <div
              onClick={handleExpand}
              className="cursor-pointer"
              data-id={id}
              data-expanded={expanded.toString()}
              data-action="individual-expand">
              <WolfTooltip title={expanded ? 'Collapse' : 'Show all steps'}>
                <IconButton className="!p-2">{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
              </WolfTooltip>
            </div>
          )}
          {data.isExternal && !data.isTrigger && (
            <Box className={'flex items-center ' + (showTools ? 'opacity-100' : 'opacity-0')}>
              <div className="cursor-pointer" data-id={data.categoryId} data-action="category-add">
                <WolfTooltip title="Add the flow containing this process to the graph">
                  <IconButton className="!p-1" onClick={toggleIsDrawn}>
                    <AddCircleOutline />
                  </IconButton>
                </WolfTooltip>
              </div>
              <div
                className="cursor-pointer"
                data-origin={id}
                data-id={data.categoryId}
                data-action="category-show-link">
                <WolfTooltip title="Show the flow containing this process (if it exists)">
                  <span>
                    <IconButton className="!p-1" disabled={!isDrawn} onClick={toggleShowLink}>
                      <Cable />
                    </IconButton>
                  </span>
                </WolfTooltip>
              </div>
            </Box>
          )}
        </Box>

        {/* Steps List */}
        {data.steps && (
          <Collapse in={expanded}>
            <Box className="mt-4 pl-4">
              {filterStepsInsideSwitchRepeat(data.steps).map((step, idx) => (
                <div key={step.stepId}>
                  <Box className={`flex items-center py-2`}>
                    <Box className="flex items-center justify-center w-6 h-6 rounded-md bg-gray-200 mr-3">
                      <WolfStepIndicator
                        text={(idx + 1).toString()}
                        type={step.stepType}
                        isSub={false}
                        isProcessMap={true}
                      />
                    </Box>
                    <Typography className="!ml-4 text-sm">
                      {step.name.length > 20 ? step.name.slice(0, 20) + '...' : step.name}
                    </Typography>
                  </Box>
                  {[StepType.REPEAT, StepType.SWITCH].includes(step.stepType) &&
                    getStepsInsideSwitchRepeat(step?.stepData?.stepsIds, data.steps).map((subStep, subIdx) => (
                      <Box key={subStep.stepId} className={`flex items-center py-2 ml-4`}>
                        <Box className="flex items-center justify-center w-6 h-6 rounded-md bg-gray-200 mr-3">
                          <WolfStepIndicator
                            text={(idx + 1).toString() + '.' + (subIdx + 1).toString()}
                            type={step.stepType} // We use the stepType of the parent step to display the icon
                            isSub={false}
                            isProcessMap={true}
                          />
                        </Box>
                        <Typography className="!ml-4 text-sm">
                          {subStep.name.length > 20 ? subStep.name.slice(0, 20) + '...' : subStep.name}
                        </Typography>
                      </Box>
                    ))}
                </div>
              ))}
            </Box>
          </Collapse>
        )}
      </Box>
    </div>
  );
};

export const AuxNodeProcessLegend: React.FC<{
  data: NodeData;
}> = ({ data }) => {
  const dark = isColorDark(data.backgroundColor || '#FFFFFF');

  return (
    <div className="relative flex items-center shadow-lg rounded-lg border border-additionalBorder bg-white max-w-72">
      <Box sx={{ backgroundColor: data.backgroundColor + '55' }} className="h-18 w-full">
        {/* Node Content */}
        <Box className="flex items-center justify-between w-full p-4 ">
          <Box className="flex items-center">
            <Box className="w-10 h-10 rounded-full flex items-center justify-center mr-2">
              <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color || '#000000'} />
            </Box>
            <Box className="flex flex-wrap items-center mr-10">
              {data.preLabel && (
                <Typography
                  className={`w-100 text-xs ${dark ? 'text-white' : 'text-black'}`}
                  component="span"
                  title={data?.preLabel}>
                  {data?.preLabel?.slice(0, 30) + (data?.preLabel?.length > 30 ? '...' : '') + ' - '}
                </Typography>
              )}
              <Typography
                className={`!font-bold ${dark ? 'text-white' : 'text-black'}`}
                component="span"
                title={data.label}>
                {data.label.slice(0, 30) + (data.label.length > 30 ? '...' : '')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ProcessNode;
