export const EXPANDED_NODE_BUFFER = 100;
export const CONTAINER_PADDING = 30; // Padding for the container
export const NODE_MARGIN = 10; // Minimum margin from container borders
export const TRIGGER_HORIZONTAL_OFFSET = 300; // Horizontal offset for triggers
export const TRIGGER_VERTICAL_SPACING = 75; // Vertical spacing between triggers
export const CONTAINER_VERTICAL_SPACING = 300; // Vertical spacing between containers
export const EXPANDED_CONTAINER_BUFFER = 50;
export const CONTAINER_TOP_PADDING = 100;
export const NODE_HORIZONTAL_SPACING = 500;
export const NODE_BRANCH_SPACING = 200;
