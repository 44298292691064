import {
  FlowVisualization,
  NodePosition,
  ProcessConnection,
  ProcessTriggerRelation,
  ReactFlowEdge,
  ReactFlowNode,
} from '@models/process-map.model';
import { Process } from '@models/process.model';
import { TimingType } from '@models/step.model';
import { MarkerType } from 'reactflow';
import {
  CONTAINER_PADDING,
  CONTAINER_TOP_PADDING,
  CONTAINER_VERTICAL_SPACING,
  NODE_BRANCH_SPACING,
  NODE_HORIZONTAL_SPACING,
  NODE_MARGIN,
} from './processMapConstants';

// Add this type near the top of the file
interface ProcessRelation {
  origin: string;
  destination: string;
}

// Add this function to transform your process data to include steps
export function generateMainProcesses(
  processes: Process[],
  identifier: string = 'flow',
  startingVerticalOffset: number = 0,
): FlowVisualization {
  // Create container node with initial padding
  const containerId = `container-${identifier}`;
  const containerNode: ReactFlowNode = {
    id: containerId,
    position: {
      x: 0,
      y: startingVerticalOffset ? startingVerticalOffset + CONTAINER_VERTICAL_SPACING : CONTAINER_VERTICAL_SPACING,
    },
    data: {
      originalY: startingVerticalOffset ? startingVerticalOffset + 100 : 0,
      id: containerId,
      categoryId: processes[0]?.service?.serviceId + '---' + processes[0]?.category,
      label: processes[0]?.category || '',
      color: processes[0]?.service?.color || '#F0F0F0',
      preLabel: processes[0] ? `${processes[0].service?.area?.name || ''} / ${processes[0].service?.name || ''}` : '',
    },
    type: 'CategoryContainer',
  };

  // Track all referenced processes and relations
  const referencedProcessIds = new Set<string>();
  const related: ProcessRelation[] = [];

  // Create a Set of current category process IDs for faster lookup
  const categoryProcessIds = new Set(processes.map((p) => p.processId));

  // Build connections array
  const processConnections: ProcessConnection[] = processes.map((process) => ({
    processId: process.processId,
    connections:
      process.steps?.reduce(
        (acc, step) => {
          if (step.stepData.executeProcessId) {
            referencedProcessIds.add(step.stepData.executeProcessId);

            // Only add to related if the target process is not in the current category
            if (!categoryProcessIds.has(step.stepData.executeProcessId)) {
              related.push({
                origin: process.processId,
                destination: step.stepData.executeProcessId,
              });
            }

            acc.push({
              targetId: step.stepData.executeProcessId,
              sourceStep: step.name,
              condition: step.stepData.condition,
            });
          }
          return acc;
        },
        [] as Array<{ targetId: string; sourceStep: string; condition?: string }>,
      ) || [],
  }));

  // Find root process
  const rootProcesses = processes
    .filter((p) => !referencedProcessIds.has(p.processId))
    .sort((a, b) => a.order - b.order);

  if (rootProcesses.length === 0) {
    return {
      nodes: [],
      edges: [],
      containerHeight: 0,
      triggers: [],
      related: [],
    };
  }

  // Create a map to track process relationships
  const processRelationships = new Map<string, Set<string>>();

  // Build the relationship map
  processes.forEach((process) => {
    processRelationships.set(process.processId, new Set());
  });

  processConnections.forEach((pc) => {
    pc.connections.forEach((conn) => {
      const sourceSet = processRelationships.get(pc.processId);
      if (sourceSet) {
        sourceSet.add(conn.targetId);
      }
    });
  });

  // Collect triggers from processes, starting with the first process
  const triggers: ProcessTriggerRelation[] = processes.reduce((acc: ProcessTriggerRelation[], process) => {
    // Add normal triggers if they exist
    if (process.triggers) {
      acc.push({
        origin: process.processId,
        triggers: process.triggers.map((trigger) => ({
          id: trigger.triggerId,
          name: trigger.name,
          type: trigger.triggerType as string,
        })),
      });
    }

    // Add triggeredBy as process triggers if they exist and aren't already connected
    if (process.triggeredBy && process.triggeredBy.length > 0) {
      process.triggeredBy.forEach((triggerStep) => {
        if (!triggerStep.process) return; // Skip if no process info

        const triggeringProcessId = triggerStep.process.processId;

        // Check if this triggeredBy process is already connected through normal flow
        const isAlreadyConnected = processConnections.some(
          (pc) =>
            pc.processId === triggeringProcessId && pc.connections.some((conn) => conn.targetId === process.processId),
        );

        // Only add if it's not already connected and not in the same category
        if (!isAlreadyConnected && !categoryProcessIds.has(triggeringProcessId)) {
          const existingTriggerIndex = acc.findIndex((t) => t.origin === process.processId);
          const triggerData = {
            id: 'triggeredBy' + triggeringProcessId,
            processId: triggeringProcessId,
            name: `${triggerStep.process.name} - ${triggerStep.name}`,
            type: 'process',
          };

          if (existingTriggerIndex !== -1) {
            // Add to existing triggers array
            acc[existingTriggerIndex].triggers.push(triggerData);
          } else {
            // Create new triggers array
            acc.push({
              origin: process.processId,
              triggers: [triggerData],
            });
          }
        }
      });
    }

    // Add warning trigger for processes without connections (except first process)
    if (process.processId !== processes[0].processId) {
      const hasNoConnections = !processConnections.some(
        (pc) =>
          // Check if this process has any outgoing connections
          (pc.processId === process.processId && pc.connections.length > 0) ||
          // Check if any other process connects to this one
          processConnections.some((otherPc) => otherPc.connections.some((conn) => conn.targetId === process.processId)),
      );

      if (hasNoConnections) {
        acc.push({
          origin: process.processId,
          triggers: [
            {
              id: 'warning',
              name: 'No connections with other processes in this category',
              type: 'warning',
            },
          ],
        });
      }
    }

    return acc;
  }, [] as ProcessTriggerRelation[]);

  // Function to check if two processes are connected (directly or indirectly)
  const areProcessesConnected = (process1: string, process2: string, visited = new Set<string>()): boolean => {
    if (visited.has(process1)) return false;
    visited.add(process1);

    const connections = processRelationships.get(process1);
    if (!connections) return false;

    if (connections.has(process2)) return true;

    return Array.from(connections).some((connectedId) => areProcessesConnected(connectedId, process2, visited));
  };

  // Group processes into connected components
  const components: Process[][] = [];
  const assignedProcesses = new Set<string>();

  processes.forEach((process) => {
    if (assignedProcesses.has(process.processId)) return;

    const component: Process[] = [process];
    assignedProcesses.add(process.processId);

    processes.forEach((otherProcess) => {
      if (process.processId !== otherProcess.processId && !assignedProcesses.has(otherProcess.processId)) {
        if (
          areProcessesConnected(process.processId, otherProcess.processId) ||
          areProcessesConnected(otherProcess.processId, process.processId)
        ) {
          component.push(otherProcess);
          assignedProcesses.add(otherProcess.processId);
        }
      }
    });

    components.push(component);
  });

  // Calculate positions for all processes
  const nodePositions: NodePosition[] = [];
  let currentX = CONTAINER_PADDING + NODE_MARGIN;

  components.forEach((component) => {
    if (component.length === 1) {
      // Single unconnected process
      nodePositions.push({
        processId: component[0].processId,
        x: currentX,
        y: CONTAINER_TOP_PADDING,
        level: 0,
        branch: 0,
        isRoot: true,
      });
      currentX += NODE_HORIZONTAL_SPACING;
    } else {
      // Connected processes - calculate hierarchical layout
      const visited = new Set<string>();
      const startX = currentX;

      // Find root processes in this component
      const componentRoots = component.filter(
        (p) =>
          !component.some((op) =>
            processConnections
              .find((pc) => pc.processId === op.processId)
              ?.connections.some((conn) => conn.targetId === p.processId),
          ),
      );

      const calculateHierarchicalPositions = (processId: string, level: number = 0, branch: number = 0): void => {
        if (visited.has(processId)) return;
        visited.add(processId);

        nodePositions.push({
          processId,
          x: startX + level * NODE_HORIZONTAL_SPACING,
          y: CONTAINER_TOP_PADDING + branch * NODE_BRANCH_SPACING,
          level,
          branch,
          isRoot: level === 0,
        });

        const connections = processConnections.find((pc) => pc.processId === processId)?.connections || [];

        connections.forEach((conn, idx) => {
          if (component.some((p) => p.processId === conn.targetId)) {
            calculateHierarchicalPositions(conn.targetId, level + 1, branch + idx);
          }
        });
      };

      // Calculate positions for each root in the component
      componentRoots.forEach((root, idx) => {
        calculateHierarchicalPositions(root.processId, 0, idx);
      });

      // Update currentX to be after this component
      const componentMaxX = Math.max(
        ...nodePositions.filter((pos) => component.some((p) => p.processId === pos.processId)).map((pos) => pos.x),
      );
      currentX = componentMaxX + NODE_HORIZONTAL_SPACING;
    }
  });

  // Generate nodes and edges
  const nodes: ReactFlowNode[] = [containerNode];
  const edges: ReactFlowEdge[] = [];

  // Add nodes based on calculated positions
  nodePositions.forEach((position) => {
    const process = processes.find((p) => p.processId === position.processId);
    // Skip if process is not found
    if (!process) {
      console.warn(`Process with ID ${position.processId} not found`);
      return;
    }

    const nodeId = `${identifier}-${position.processId}`;

    nodes.push({
      id: nodeId,
      position: { x: position.x, y: position.y },
      data: {
        originalY: position.y,
        label: process.name || '',
        color: process.service?.color || '#000000',
        isExternal: false,
        parentNode: containerId,
        steps: process.steps || [],
        expand: false,
      },
      isRoot: position.isRoot,
      originalId: process.processId,
      parentNode: containerId,
      type: 'ProcessV2',
    });
  });

  // Add edges with safety checks and timing nodes
  processConnections.forEach((pc) => {
    pc.connections.forEach((conn, index) => {
      // Verify both source and target processes exist
      const sourceExists = processes.some((p) => p.processId === pc.processId);
      const targetExists = processes.some((p) => p.processId === conn.targetId);

      if (sourceExists && targetExists) {
        const sourceNode = nodes.find((n) => n.id === `${identifier}-${pc.processId}`);
        const targetNode = nodes.find((n) => n.id === `${identifier}-${conn.targetId}`);

        if (sourceNode && targetNode && conn.sourceStep) {
          // Find the step that contains this connection
          const sourceProcess = processes.find((p) => p.processId === pc.processId);
          const step = sourceProcess?.steps?.find((s) => s.name === conn.sourceStep);

          if (step?.stepData?.timingType && step.stepData.timingType !== TimingType.IMMEDIATE) {
            // Create timing node
            const timingNodeId = `${identifier}-timing-${pc.processId}-${conn.targetId}-${index}`;

            // Calculate midpoint
            const midX = (sourceNode.position.x + 250 + targetNode.position.x) / 2;
            const midY = (sourceNode.position.y + targetNode.position.y + 102) / 2;

            // Add offset to position the timing node above the line
            const timingPosition = {
              x: midX,
              y: midY - 40, // Offset above the connection line
            };

            nodes.push({
              id: timingNodeId,
              position: timingPosition,
              data: {
                timingType: step.stepData.timingType,
                label: step.stepData.timingName,
                preLabel: step.stepData.timingDescription,
                parentNode: containerId,
              },
              parentNode: containerId,
              type: 'TimingNode',
            });

            // Create two edges: source->timing and timing->target
            edges.push({
              id: `${identifier}-edge-to-timing-${pc.processId}-${conn.targetId}-${index}`,
              source: sourceNode.id,
              target: timingNodeId,
              sourceHandle: 'right',
              targetHandle: 'left',
              markerEnd: {
                type: MarkerType.Arrow,
                width: 30,
                height: 30,
              },
              type: 'step',
            });

            edges.push({
              id: `${identifier}-edge-from-timing-${pc.processId}-${conn.targetId}-${index}`,
              source: timingNodeId,
              target: targetNode.id,
              type: 'step',
              markerEnd: {
                type: MarkerType.Arrow,
                width: 30,
                height: 30,
              },
            });
          } else {
            // Create direct edge if no timing or if timing is immediate
            edges.push({
              id: `${identifier}-edge-${pc.processId.slice(0, 8)}-${conn.targetId.slice(0, 8)}-${index}`,
              source: sourceNode.id,
              target: targetNode.id,
              type: 'step',
              markerEnd: {
                type: MarkerType.Arrow,
                width: 30,
                height: 30,
              },
            });
          }
        }
      }
    });
  });

  // Calculate container dimensions based on the lowest element
  const allPositions = nodes
    .filter((node) => node.id !== containerId) // Exclude container itself
    .map((node) => {
      const height =
        node.type === 'ProcessV2' && node.data.expand
          ? (node.data.steps?.length || 0) * 40 // Account for expanded process height
          : 0;
      return node.position.y + height + 100; // Add node height and some padding
    });

  const maxY = Math.max(...allPositions);
  const maxX = Math.max(...nodePositions.map((pos) => pos.x)) + NODE_HORIZONTAL_SPACING;

  // Update container dimensions
  containerNode.data.height = maxY + CONTAINER_PADDING;
  containerNode.data.originalHeight = maxY + CONTAINER_PADDING;
  containerNode.data.width = maxX + CONTAINER_PADDING;
  containerNode.data.originalWidth = maxX + CONTAINER_PADDING;

  return {
    nodes,
    edges,
    containerHeight: containerNode.data.height,
    triggers,
    related,
  };
}
