import CustomModal from '@components/CustomModal';
import AddProcessForm from '@components/drawers/AddProcessForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import GridListSubheader from '@components/GridListSubheader';
import ItemWithSummaryDrag from '@components/ItemWithSummaryDrag';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import WolfHelperNavigation from '@components/ui/WolfHelperNavigation';
import { graphQlClient } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import ServicesGraphQL from '@graphql/service.queries';
import { useLoader } from '@hooks/useLoader';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { Area } from '@models/area.model';
import { DrawerType, SnackType } from '@models/common.model';
import { createOrUpdateOrganizationRequest } from '@models/organization.model';
import {
  CategorizedProcess,
  createOrUpdateProcessRequest,
  deleteProcessRequest,
  getProcessesByServiceIdRequest,
  getProcessesWithAreaServiceRequest,
  getProcessWithTriggeredByRequest,
  Process,
  processPolicyLinkRequest,
  reorderProcessesRequest,
} from '@models/process.model';
import { getServiceByIDRequest, Service } from '@models/service.model';
import { WolfieStatus } from '@models/wolfie.model';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import {
  clearConfirmedWolfieElements,
  disableWolfie,
  removeFromWolfieConfirmedElements,
  removeFromWolfieData,
  setWolfieContextInfo,
  setWolfieEnabled,
  setWolfieMinimized,
  setWolfieStatus,
  setWolfieUnableToConfirmElement,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { categorizeProcesses } from '@utils/categorizeProcesses';
import { categorizeProcessesByService } from '@utils/categorizeProcessesByService';
import { chkIdx } from '@utils/chkIdx';
import { notValidDrop } from '@utils/notValidDrop';
import { useEffect, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

const ProcessList: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocationQuery();
  const { setLoading } = useLoader();

  const locPr = useTranslation('processes');
  const localeCommon = useTranslation('common');
  const locAM = useTranslation('actionMessages');

  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [catedProcList, setcatedProcList] = useState<CategorizedProcess>();
  const [categories, setCategories] = useState<string[]>([]);
  const [categorizedProcessesByService, setCategorizedProcessesByService] = useState<any>();
  const [categoriesByService, setCategoriesByService] = useState<any>();
  const [selectedService, setSelectedService] = useState<Partial<Service>>();
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.ADD);
  const [entityData, setEntityData] = useState<Process>();
  const [saving, setSaving] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<any>();
  const [isGenerating, setIsGenerating] = useState(false);

  const {
    data: wolfieData,
    status: wolfieStatus,
    confirmedElements,
    permanentlyDisabled: permanentlyDisabled,
    enabled: softEnabledWolfie,
    loading: wolfieLoading,
  } = useAppSelector((state: RootState) => state.wolfie);
  const { organizationId, onboardingDetails } = useAppSelector((state: RootState) => state.organization);
  const enabledWolfie = process.env.REACT_APP_ENABLE_WOLFIE === 'true' && !permanentlyDisabled;
  const cardRefs = useRef<{ [key: string]: any | null }>({});
  useUnsavedChangesWarning({ showWarning: isGenerating });
  const [processFormChanges, setProcessFormChanges] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);

  // Get params & get services by areaId or all services if no ID
  useEffect(() => {
    const serviceId = query.get('serviceId');
    if (!serviceId || serviceId === 'undefined') {
      getProcesses();
    } else {
      getProcessesByServiceId(serviceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (wolfieData && softEnabledWolfie && !permanentlyDisabled) {
      handleSuggestedProcesses(wolfieData);
    }
  }, [wolfieData, softEnabledWolfie]);

  useEffect(() => {
    console.log('confirmedElements', confirmedElements);
    console.log('wolfieStatus', wolfieStatus);
    console.log('softEnabledWolfie', softEnabledWolfie);
    console.log('wolfieLoading', wolfieLoading);
    console.log('permanentlyDisabled', permanentlyDisabled);

    if (
      softEnabledWolfie &&
      wolfieStatus === WolfieStatus.RESEARCHING &&
      confirmedElements.length > 0 &&
      !permanentlyDisabled
    ) {
      handleConfirmProcessById(confirmedElements[confirmedElements.length - 1]);
    }
    if (
      softEnabledWolfie &&
      wolfieStatus === WolfieStatus.DONE &&
      confirmedElements.length > 0 &&
      !wolfieLoading &&
      !permanentlyDisabled
    ) {
      multipleElementsConfirmWrapper(confirmedElements);
    }
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.DECLINED) {
      setIsGenerating(false);
      dispatch(disableWolfie());
      setServices(services.filter((service: Service) => !service.isWolfieGenerated));
      refreshList();
    }

    if (!softEnabledWolfie && !permanentlyDisabled) {
      setIsGenerating(false);
    }
  }, [confirmedElements, wolfieStatus, softEnabledWolfie, wolfieLoading, permanentlyDisabled]);

  const refreshList = () => {
    const serviceId = selectedService?.serviceId || query.get('serviceId');
    if (!serviceId || serviceId === 'undefined') {
      getProcesses();
    } else {
      getProcessesByServiceId(serviceId);
    }
  };

  //  // DRAWER HANDLE *********************
  const drawerNewOpen = () => {
    setDrawerType(DrawerType.ADD);
    setShowDrawer(true);
  };

  const drawerEditOpen = async (id: string) => {
    setDrawerType(DrawerType.EDIT);
    setShowDrawer(true);
    const processToEdit: getProcessWithTriggeredByRequest = await graphQlClient.request(
      ProcessesGraphQL.queries.getProcessWithTriggeredBy,
      { id },
    );
    if (processToEdit) {
      setEntityData(processToEdit.getProcessWithTriggeredBy);
    }
  };

  const drawerClose = (): void => {
    setShowDrawer(false);
    setEntityData(undefined);
    refreshList();
  };

  // Drag and Drop
  const handleDragEnd = async (result: DropResult) => {
    if (notValidDrop(result)) return;
    const newPr: Process[] = [...processes];
    const destinationId = catedProcList?.[result.destination!.droppableId]?.[result.destination!.index].processId; //Checked in notValidDrop

    const change = result.destination!.index - result.source.index;

    let auxDest = chkIdx(newPr.findIndex((i) => i.processId === destinationId) + change, newPr.length, 0);
    let auxSrc = chkIdx(newPr.findIndex((i) => i.processId === result.draggableId) + 0, newPr.length, 0);

    const prev = newPr[auxDest];
    newPr[auxDest] = newPr[auxSrc];
    newPr[auxSrc] = prev;

    const processesIds = newPr.map((item: Process) => item.processId);

    try {
      const data: reorderProcessesRequest = await graphQlClient.request(ProcessesGraphQL.mutations.reorderProcesses, {
        processes: { processesIds },
      });
      if (data?.reorderProcesses.messages[0] === 'Update successful') {
        newPr.forEach((proc, index) => {
          proc.order = index + 1;
        });
        dispatch(
          appendActionMessage({
            message: locAM['processesReorderedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        refreshProcessList(null, null, newPr);
      }
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  // CRUD Operations (incl Drawer save new/edit)
  const getProcesses = async () => {
    try {
      setLoading(true);
      const data: getProcessesWithAreaServiceRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesWithAreaService,
      );
      refreshProcessList(
        null,
        null,
        data?.getProcessesWithAreaService.sort((a, b) => (a.order > b.order ? 1 : -1)),
      );
      const newServices: Service[] = [];
      data?.getProcessesWithAreaService.forEach((process: Process) => {
        if (process.service && !newServices.some((service) => process?.service?.serviceId === service.serviceId)) {
          newServices.push(process.service);
        }
      });
      const newAreas: Area[] = [];
      newServices.forEach((service: Service) => {
        if (service.area && !newAreas.some((area) => service?.area?.areaId === area.areaId)) {
          newAreas.push(service.area);
        }
      });
      const { categorizedProcessesByService, categoriesByService } = categorizeProcessesByService(
        data?.getProcessesWithAreaService,
        newServices,
      );
      setAreas(newAreas);
      setServices(newServices);
      setCategorizedProcessesByService(categorizedProcessesByService);
      setCategoriesByService(categoriesByService);
    } catch (e: any) {
      showErrorSnack(e);
    } finally {
      setLoading(false);
    }
  };

  const getProcessesByServiceId = async (id: string) => {
    try {
      setLoading(true);

      const variables = { id };
      const data: getProcessesByServiceIdRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesByServiceId,
        variables,
      );
      refreshProcessList(
        null,
        null,
        data?.getProcessesByServiceId.sort((a, b) => (a.order > b.order ? 1 : -1)),
      );
      if (data?.getProcessesByServiceId?.length === 0) {
        getServiceById(id);
      } else {
        setSelectedService(data?.getProcessesByServiceId[0]?.service);
        setSelectedArea(data?.getProcessesByServiceId?.[0]?.service?.area);
      }
      const selectedCategory = query.get('category');
      if (selectedCategory) {
        setTimeout(() => {
          scrollToCard(data?.getProcessesByServiceId[0]?.service?.name + '-' + selectedCategory);
        }, 300);
      }
    } catch (e: any) {
      showErrorSnack(e);
    } finally {
      setLoading(false);
    }
  };

  const getServiceById = async (id: string) => {
    setLoading(true);
    try {
      const variables = { id };
      const data: getServiceByIDRequest = await graphQlClient.request(
        ServicesGraphQL.queries.getServiceByID,
        variables,
      );
      setSelectedService(data?.getServiceByID);
      setSelectedArea(data?.getServiceByID?.area);
      if (data?.getServiceByID?.processes.length === 0 && enabledWolfie) {
        setIsGenerating(true);
        dispatch(setWolfieEnabled(true));
        dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
        dispatch(
          setWolfieContextInfo({
            name: data?.getServiceByID?.name,
            description: data?.getServiceByID?.description,
            areaContext: {
              name: data?.getServiceByID?.area?.name,
              description: data?.getServiceByID?.area?.description,
            },
          }),
        );
        dispatch(setWolfieMinimized(false));
      }
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const deleteResult: deleteProcessRequest = await graphQlClient.request(ProcessesGraphQL.mutations.deleteProcess, {
        id,
      });
      if (deleteResult?.deleteProcess?.affected > 0) {
        const newProcesses = processes.filter((process: Process) => process.processId !== id);
        dispatch(appendActionMessage({ message: locAM['deleteSuccessful'], type: SnackType.SUCCESS }));
        refreshProcessList(null, null, newProcesses);
      }
      setShowDrawer(false);
      setEntityData(undefined);
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  const handleDrawerSave = async (process: Process, policies: { text: string; value: string }[]) => {
    if (!process.name || !process.goal || !process.serviceId) {
      dispatch(
        appendActionMessage({
          message: locAM['processNameGoalServiceRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    setSaving(true);
    const isEdit = drawerType === DrawerType.EDIT;
    if (isEdit && !process.processId) {
      return appendActionMessage({
        message: localeCommon['somethingWentWrong'],
        type: SnackType.ERROR,
      });
    }
    const auxPolicy = policies?.map((policy: { text: string; value: string }) => policy.value);
    try {
      if (isEdit) {
        //Remove policies, triggers, steps from the process object since they are updated separately
        delete process.policies;
        delete process.triggers;
        delete process.steps;
        delete process.service;
        delete process.location;
        delete process.triggeredBy;
        delete process.generatedFromName;
        delete process.generatedFrom;
      }
      const query =
        isEdit && process.processId
          ? ProcessesGraphQL.mutations.updateProcess
          : ProcessesGraphQL.mutations.createProcess;
      const params = {
        process: {
          ...process,
          serviceId: !isEdit ? selectedService?.serviceId || process.serviceId : process.serviceId,
        },
      };
      const data: createOrUpdateProcessRequest = await graphQlClient.request(query, params);
      if (data?.createProcess?.processId || data?.updateProcess?.processId) {
        if (
          !onboardingDetails?.processCreated &&
          processes.filter((process: Process) => !process.isWolfieGenerated).length === 0
        ) {
          updateOrganizationProcessCreated();
        }
        // Update the linked policies
        if (auxPolicy) {
          handleLinkProcessPolicies(process?.processId, auxPolicy);
        }

        // Update the processes list
        let newProcesses: Process[] = [];
        if (isEdit) {
          const updatedProcess: Process = data?.updateProcess as Process;
          newProcesses = processes.filter(
            (auxProcess: Process) => auxProcess.processId !== process.processId,
            updatedProcess,
          );
        } else {
          newProcesses = [...processes, data.createProcess as Process];
        }
        dispatch(
          appendActionMessage({
            message: isEdit ? locAM['updateSuccessful'] : locAM['processCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        refreshProcessList(null, null, newProcesses);
        drawerClose();
        if (!isEdit && data?.createProcess?.processId) {
          history.push(`/processes/${data.createProcess.processId}`);
        }
      }
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  const refreshProcessList = (
    updatedProcessIndex: number | null,
    updatedProcess: Process | null,
    newProcessesList?: Process[],
  ) => {
    setSaving(false);
    let newProcesses: Process[] = [];
    if (newProcessesList) {
      newProcesses = newProcessesList;
    } else if (updatedProcessIndex !== null && updatedProcess) {
      newProcesses = [...processes];
      newProcesses[updatedProcessIndex] = updatedProcess;
    } else {
      return;
    }
    setProcesses(newProcesses);
    const { categorizedProcesses, categories } = categorizeProcesses(newProcesses);
    setcatedProcList(categorizedProcesses);
    setCategories(categories);
  };

  const handleSuggestedProcesses = (generatedProcesses: any[]) => {
    if (!generatedProcesses || generatedProcesses?.length === 0 || !Array.isArray(generatedProcesses)) return;
    generatedProcesses.forEach((auxProc, idx) => {
      auxProc.description = auxProc.descriptionText || '';
      auxProc.goal = auxProc.goal || '';
      auxProc.processId = auxProc.id;
      auxProc.order = idx + 1;
      // auxProc.category = auxProc.category || '';
    });

    setProcesses((prev) => {
      const newProcesses = [
        ...prev.filter((auxProcess: Process) => !auxProcess.isWolfieGenerated),
        ...generatedProcesses,
      ];
      // Re-categorize the processes based on the updated list, we do this to get the actual updated processes
      const { categorizedProcesses, categories } = categorizeProcesses(newProcesses);
      setcatedProcList(categorizedProcesses);
      setCategories(categories);
      return newProcesses;
    });
  };

  const multipleElementsConfirmWrapper = async (ids: string[]) => {
    let currentId = '';
    let success = true;
    try {
      for (let id of ids) {
        currentId = id;
        success = (await handleConfirmProcessById(id, ids[0] === id, false)) || false;
        if (!success) {
          break;
        }
      }
      if (!success) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(clearConfirmedWolfieElements());
      }
      setIsGenerating(false);
    } catch {}
  };

  const handleConfirmProcessById = async (id: string, showSnack: boolean = true, showErrorSnack: boolean = true) => {
    if (!id) return;
    const auxProcess = processes.find((process: Process) => process.processId === id);
    if (!auxProcess) return;
    try {
      const params = {
        process: {
          name: auxProcess.name,
          description: auxProcess.description,
          category: auxProcess.category,
          goal: auxProcess.goal,
          order: auxProcess.order,
          serviceId: selectedService?.serviceId,
        },
      };
      const data: createOrUpdateProcessRequest = await graphQlClient.request(
        ProcessesGraphQL.mutations.createProcess,
        params,
      );
      if (data?.createProcess?.processId) {
        if (
          !onboardingDetails?.processCreated &&
          processes.filter((process: Process) => !process.isWolfieGenerated).length === 0
        ) {
          updateOrganizationProcessCreated();
        }
        const updatedProcess: Process = data?.createProcess as Process;
        if (showSnack) {
          dispatch(
            appendActionMessage({
              message: locAM['processCreatedSuccessfully'],
              type: SnackType.SUCCESS,
            }),
          );
        }
        dispatch(removeFromWolfieData(id));
        dispatch(removeFromWolfieConfirmedElements(id));
        setProcesses((prev) => {
          const prevIndex = prev.findIndex((auxProcess: Process) => auxProcess.processId === id);
          const newProcesses = [...prev.slice(0, prevIndex), updatedProcess, ...prev.slice(prevIndex + 1)];
          // Re-categorize the processes based on the updated list, we do this to get the actual updated processes
          const { categorizedProcesses, categories } = categorizeProcesses(newProcesses);
          setcatedProcList(categorizedProcesses);
          setCategories(categories);
          return newProcesses;
        });
      }
      return true;
    } catch {
      if (showErrorSnack) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(removeFromWolfieConfirmedElements(id));
      }
      return false;
    }
  };

  const handleLinkProcessPolicies = async (processId: string, policies: string[]) => {
    try {
      const updateResult: processPolicyLinkRequest = await graphQlClient.request(
        ProcessesGraphQL.mutations.linkProcessPolicy,
        {
          processId,
          policyId: JSON.stringify(policies),
        },
      );
      if (updateResult?.linkProcessPolicy?.processId) {
        const updatedProcess = processes.find((process: Process) => process.processId === processId);
        if (updatedProcess) {
          updatedProcess.policies = updateResult.linkProcessPolicy.policies;
          const updatedProcessIndex = processes.findIndex((process: Process) => process.processId === processId);
          refreshProcessList(updatedProcessIndex, updatedProcess, undefined);
        }
      }
    } catch (e: any) {}
  };

  const updateOrganizationProcessCreated = async () => {
    try {
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            onboardingDetails: {
              ...onboardingDetails,
              processCreated: true,
            },
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  const showErrorSnack = (e: any) => {
    setSaving(false);
    dispatch(
      appendActionMessage({
        message: e?.response?.errors[0]?.message || localeCommon['requestError'],
        type: SnackType.ERROR,
      }),
    );
  };

  const handleProcessClick = (id: string) => {
    history.push(`/processes/${id}`);
  };

  const processCardList = categories.sort().map((category: string) => {
    return (
      <Box
        key={category}
        sx={{ scrollMargin: 100 }}
        ref={(el) => (cardRefs.current[selectedService?.name + '-' + category] = el)}>
        <div>
          <Box className="flex items-center my-8">
            <Typography variant="h4semibold" className="ml-2 cursor pointer">
              {category}
            </Typography>
          </Box>
          <ItemWithSummaryDrag
            elements={catedProcList?.[category] || []}
            elementIdName="processId"
            showDrag={!!selectedArea}
            isGridView={isGridView}
            hasCalendar={false}
            hasStorage={false}
            droppableId={category}
            handleDragEnd={handleDragEnd}
            drawerEditOpen={drawerEditOpen}
            handleCardClick={handleProcessClick}
          />
        </div>
      </Box>
    );
  });

  const allAreasProcessCardList = areas.sort().map((area: Area) => {
    return (
      <div key={area.areaId}>
        {services
          .filter((service) => service.area?.areaId === area.areaId)
          .sort()
          .map((service: Service) => {
            return (
              <div key={service.serviceId}>
                {categoriesByService[service.name].map((category: string) => {
                  return (
                    <Box
                      key={category}
                      sx={{ scrollMargin: 100 }}
                      ref={(el) => (cardRefs.current[service.name + '-' + category] = el)}>
                      <Box className="flex items-center my-8">
                        <Typography variant="h4" className="mx-2">
                          {area.name + ' / ' + service.name + ' / '}
                        </Typography>
                        <Typography variant="h4semibold" className="ml-2 cursor pointer">
                          {category}
                        </Typography>
                      </Box>

                      <ItemWithSummaryDrag
                        cardsRefs={cardRefs}
                        elements={categorizedProcessesByService[service.name][category]}
                        isGridView={isGridView}
                        elementIdName="processId"
                        showDrag={!!selectedArea}
                        hasCalendar={false}
                        hasStorage={false}
                        droppableId={service.name + '-' + category}
                        handleDragEnd={handleDragEnd}
                        drawerEditOpen={drawerEditOpen}
                        handleCardClick={handleProcessClick}
                      />
                    </Box>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  });

  const allAreasNavigation = areas.sort().map((area: Area) => {
    return (
      <div key={area.areaId}>
        {services
          .filter((service) => service.area?.areaId === area.areaId)
          .sort()
          .map((service: Service) => {
            return (
              <div key={service.serviceId}>
                {categoriesByService[service.name].map((category: string) => {
                  return (
                    <div key={category}>
                      <WolfHelperNavigation
                        type={'title'}
                        label={service?.area?.name + ' - ' + service.name + ' - ' + category}
                        selected={selectedNavigationItem === category}
                        onSelect={() => {
                          scrollToCard(service.name + '-' + category);
                        }}
                      />
                      {categorizedProcessesByService[service.name][category].map((process: Process) => {
                        return (
                          <WolfHelperNavigation
                            key={process.processId}
                            type={'section'}
                            label={process.name}
                            selected={selectedNavigationItem === process.processId}
                            onSelect={() => {
                              scrollToCard(process.processId);
                            }}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  });

  const selectedNavigation = categories.map((category: string) => {
    return (
      <div key={category}>
        <WolfHelperNavigation
          type={'title'}
          label={category}
          selected={selectedNavigationItem === category}
          onSelect={() => {
            scrollToCard(selectedService?.name + '-' + category);
          }}
        />
        {catedProcList?.[category].map((process: Process) => {
          return (
            <WolfHelperNavigation
              key={process.processId}
              type={'section'}
              label={process.name}
              selected={selectedNavigationItem === process.processId}
              onSelect={() => {
                scrollToCard(process.processId);
              }}
            />
          );
        })}
      </div>
    );
  });

  const scrollToCard = (id: string) => {
    setSelectedNavigationItem(id);
    if (cardRefs.current[id]) {
      cardRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleToggleView = () => {
    setIsGridView(!isGridView);
  };

  const handleDrawerClose = () => {
    if (processFormChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      drawerClose();
    }
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    setProcessFormChanges(false);
    drawerClose();
  };

  const handleProcessFormChanges = (changes: boolean) => {
    setProcessFormChanges(changes);
  };

  return (
    <>
      {/* <Prompt when={isGenerating} message={localeCommon['changesNotSaved']} /> */}
      <Box className="w-full">
        <Box className="sticky top-0 z-30 bg-backgroundUI">
          <GridListSubheader
            title={localeCommon['areas']}
            titleLink="/areas"
            subTitle={selectedArea?.name || localeCommon['allAreas']}
            subTitleLink={'/services/?areaId=' + selectedService?.areaId}
            subSubTitle={selectedService?.name || locPr['allServices']}
            subSubTitleLink={'/services/' + selectedService?.serviceId}
            buttonText={locPr['addNew']}
            isGridView={isGridView}
            onToggleView={handleToggleView}
            onDrawerOpen={drawerNewOpen}
            icon="service"
            iconColor={selectedService?.color}
          />
        </Box>
        <Box className="flex">
          <Box className="w-3/4">{!selectedArea || !selectedService ? allAreasProcessCardList : processCardList}</Box>
          <Box className="w-1/4 fixed -right-16 overflow-y-scroll h-5/6">
            {!selectedArea || !selectedService ? allAreasNavigation : selectedNavigation}
          </Box>
        </Box>

        <CustomDrawer
          title={drawerType === 'edit' ? locPr['edit'] : locPr['addNew']}
          isOpen={showDrawer}
          onClose={handleDrawerClose}
          size="large">
          <AddProcessForm
            parentService={{ name: selectedService?.name || '', serviceId: selectedService?.serviceId || '' }}
            onClose={handleDrawerClose}
            handleSave={handleDrawerSave}
            handleDelete={handleDelete}
            drawerType={drawerType}
            initialData={entityData}
            saveBtnText={drawerType === DrawerType.EDIT ? locPr['editProcess'] : locPr['createProcess']}
            saving={saving}
            onChanges={handleProcessFormChanges}
          />
        </CustomDrawer>

        {showConfirmLeaveModal && (
          <CustomModal
            isOpen={!!showConfirmLeaveModal}
            title={localeCommon['confirmLeave']}
            customConfirmColor="error"
            maxWidth="600px"
            showButtons={true}
            onClose={closeConfirmLeaveModal}
            onConfirm={closeAndConfirmLeaveModal}>
            <ConfirmLeaveModal />
          </CustomModal>
        )}
      </Box>
    </>
  );
};

export default ProcessList;
