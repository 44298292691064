const en = {
  landing: {
    home: 'HOME',
    features: 'FEATURES',
    integrations: 'INTEGRATIONS',
    try: 'TRY!',
    contact: 'CONTACT',
    login: 'LOGIN',
    tagline: "AI assisted process documenting so you don't have to worry about your processes anymore!",
    highlight1: 'Document your processes so you never forget how to fix complex problems.',
    highlight2: 'Transfer knowledge throughout your organization to obtain predictable results.',
    highlight3: 'Integrate with tools you already use to get reminders and keep everything updated.',
  },
  login: {
    aiAssistedMessage: 'AI-assisted process documentation',
    welcomeBack: 'Welcome back!',
    signInGoogle: 'Sign In with Google',
    or: 'or',
    email: 'Email',
    enterEmail: 'Enter your email',
    writePasword: 'Write your password',
    password: 'Password',
    rememberMe: 'Remember me',
    rememberMeMessage: 'Check this box to stay logged in this device.',
    forgotPassword: 'Forgot password?',
    sendResetCode: 'Send reset code',
    forgotPasswordMessage: 'Enter your email to receive a reset code',
    signIn: 'Sign In',
    resetPassword: 'Reset Password',
    resetPasswordMessage: 'Enter the reset code you received and your new password',
    passwordResetCode: 'Password reset code',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    enterPasswordResetCode: 'Enter the password reset code you received',
    writeNewPassword: 'Write a new password',
    confirmNewPassword: 'Confirm the new password',
    passwordsDoNotMatch: 'Passwords do not match',
    enterTempPassword: 'Enter the temporary password you received',
    tempPassword: 'Temporary password',
    confirmUserMessage: 'Looks like you are doing the initial login, please enter a new password below',
    initialLoginMessage: 'You should have received the welcome message, please enter the password below',
  },
  //TODO: I would refactor these using element + action to avoid so much repetition
  actionMessages: {
    areasReorderedSuccessfully: 'Areas reordered successfully',
    areaDeletedSuccessfully: 'Area deleted successfully',
    areaCreatedSuccessfully: 'Area created successfully',
    areaUpdatedSuccessfully: 'Area updated successfully',
    integrationDeletedSuccessfully: 'Integration deleted successfully',
    discrepancyUpdatedSuccessfully: 'Discrepancy updated successfully',
    policyCreatedSuccessfully: 'Policy created successfully',
    policyUpdatedSuccessfully: 'Policy updated successfully',
    policyDeletedSuccessfully: 'Policy deleted successfully',
    processesReorderedSuccessfully: 'Processes reordered successfully',
    stepCreatedSuccessfully: 'Step created successfully',
    stepDeletedSuccessfully: 'Step deleted successfully',
    stepUpdatedSuccessfully: 'Step updated successfully',
    processCreatedSuccessfully: 'Process created successfully',
    processUpdatedSuccessfully: 'Process updated successfully',
    stepsReorderedSuccessfully: 'Steps reordered successfully',
    linkSuccessfullyUpdated: 'Link successfully updated',
    deleteSuccessful: 'Delete successful',
    serviceCreatedSuccessfully: 'Service created successfully',
    servicesReorderedSuccessfully: 'Services reordered successfully',
    updateSuccessful: 'Update successful',
    avatarUpdatedSuccessfully: 'Avatar updated successfully',
    profileUpdatedSuccessfully: 'Profile updated successfully',
    documentUpdatedSuccessfully: 'Document updated successfully',
    organizationCreatedSuccessfully: 'Organization created successfully',
    organizationEditedSuccessfully: 'Organization edited successfully',
    organizationEnabledSuccessfully: 'Organization enabled successfully',
    organizationDisabledSuccessfully: 'Organization deleted successfully',
    userCreatedSuccessfully: 'User created successfully',
    userEditedSuccessfully: 'User edited successfully',
    userDisabledSuccessfully: 'User deleted successfully',
    userEnabledSuccessfully: 'User enabled successfully',
    loginNoEmail: 'No email, enter your email and click "Forgot password" again',
    fillPolicyFields: 'Please fill the policy name and specify at least some content',
    fillAreaFields: 'Please fill the area name, description and color',
    fillServiceFields: 'Please pick an area, fill the service name, description and color',
    requestError: 'Request error, please try again',
    whileImpersonatingError: 'Action not allowed while impersonating',
    linkCopied: 'Link copied to clipboard',
    impersonateFailed: 'Impersonation failed',
    impersonateClearFailed: 'Impersonation clear failed',
    youMustFirstSaveTheStep: 'Unable to delete yet. You must first save the step',
    generatedFromClearSuccess: 'Generated from document cleared successfully',
    stepNameRequired: 'Step name is required',
    nameDescriptionColorRequired: 'Name, description and color are required',
    processNameGoalServiceRequired: 'Process name, goal and service are required',
    triggerNameRequired: 'Trigger name is required',
    invalidOrganizationData: 'Error.Make sure you have a name, email, first and last name and the email is valid.',
    changesSavedSuccessfully: 'Changes saved successfully',
    failedLoginAttemptsReset: 'Failed login attempts reset successfully',
  },
  areas: {
    title: 'Areas',
    addNew: 'Add New Area',
    edit: 'Edit Area',
    activity: 'Activity',
    noActivity: 'No activity yet. Here you will see the latest updates & changes.',
    createArea: 'Create Area',
    editArea: 'Edit Area',
    servicesInside: 'Services within this Area',
  },
  integrations: {
    title: 'Integrations',
    type: 'Type',
    provider: 'Provider',
    status: 'Status',
    structure: 'Structure',
    clickToEnable: 'Click to enable',
    clickToGenerateStructure: 'Click to generate structure',
    enabledGenerated: 'Enabled and Structure Generated',
    success: 'Integration successfully activated, you can now close this tab',
    checksTitle: 'Integration checks',
    confirmDelete: 'Are you sure you want to delete this integration?',
    confirmDeleteMessage: 'This will remove the existing integration of type {type} with {provider}.',
    confirmDeleteMessageCalendar:
      "The application will stop linking to your calendar account setup, however your calendars will not be deleted. If you want to remove each service's calendar, you need to do it manually",
    confirmDeleteMessageStorage:
      'The application will stop updating to your storage, however your folders will not be deleted. Folders for each area (with subfolders for services) as well as your global policy and templates folder will remain. If you wish to do so, you can remove them manually.',
    confirmGenerate: 'This will generate the integration structure.',
    confirmGenerateMessage: 'This will generate a structure for the integration of type {type} with {provider}.',
    confirmGenerateMessageCalendar:
      'The application will generate a calendar for each service, and events for every trigger of type calendar',
    confirmGenerateMessageStorage:
      'The application will generate a folder for each area, with subfolders for each service, a global folder for policies and a global policy for templates. Document will also be generated for each policy and each process.',
    generateTooltip: 'Will create a structure for the integration, click to see details',
    recheckDiscrepancies: 'Manually recheck discrepancies',
    filterDocument: 'Filter by Document Name',
    showInactive: 'Show Inactive',
    ignore: 'Ignore',
    discrepancyType: 'Discrepancy Type',
    createdAt: 'Created At',
    involvedDocument: 'Involved Document',
    foundDiscrepancy: 'Found Discrepancy',
    solution: 'Solution',
    optionPET: 'Fix from Wolf',
    optionInteg: 'Fix from Integration',
    ignorePermanently: 'Ignore Permanently',
    ignoreMonth: 'Ignore for 1 Month',
    ignoreDay: 'Ignore for 1 Day',
    ignoreCustom: 'Ignore for Custom Period',
    trustIntegration: 'Trust Integration',
    trustWolf: 'Trust Wolf',
  },
  policies: {
    addPolicy: 'Add Policy',
    category: 'Category',
    confirmDeleteTitle: 'Confirm Deletion',
    confirmDeleteMessage:
      'Are you sure you want to delete this policy? Please note that it is referenced in {count} processes.',
    content: 'Content',
    description: 'Policies are a set of rules that define the behavior of your processes.',
    policyContent: 'Policy Content',
    usedIn: 'This policy is used in the following processes:',
    view: 'View',
    contentTooltip: 'The content of the policy, all the rules that are defined by the policy',
    descriptionTooltip: 'The description of the policy, a brief explanation of what it covers',
    categoryTooltip:
      'The category of the policy, so that it can be grouped with others that cover the same issues (e.g. "Compliance", "Risk Management", "IT Security", etc.)',
  },
  processes: {
    title: 'Processes',
    addNew: 'Add New Process',
    edit: 'Edit Process',
    editTriggers: 'Edit Triggers',
    addStep: 'Add Step',
    allServices: 'All services',
    searchPlaceholder: 'Search by name or goal',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    normalStep: 'Normal Step',
    caseStep: 'Switch Step',
    loopStep: 'Repeat Step',
    condition: 'Condition',
    noCondition: 'No condition set for this step.',
    notes: 'Notes',
    noNotes: 'No notes set for this step.',
    policiesLinked: 'Policies linked',
    linkedProcess: 'Linked Process',
    noLinked: 'No linked process for this step.',
    noTriggersYet: 'No triggers defined yet.',
    generatedFrom: 'Process generated from document',
    aNewStep: 'A new step',
    generatedFromClear: 'Process generated from document, click here to clear this reference if no longer relevant',
    noPoliciesYet: 'No policies linked yet.',
    policiesClear: 'Click to unlink this policy from this process',
    selectPolicy: 'Select Policy',
    noRecurrence: 'No recurrence defined',
    dropBelow: 'Drop below',
    toMoveOutsideAsMainStep: 'to move outside as main step',
    toMoveInside: 'to move inside',
  },
  processForm: {
    name: 'Process Name',
    category: 'Process Category',
    description: 'Description',
    calenEvTriggers: 'Calendar / Event Triggers',
    evTrig: 'Event Trigger',
    evTrigName: 'Event Trigger Name',
    evTrigNamePlaceholder: 'An email from a customer',
    evTrigDescription: 'Trigger Description (Optional)',
    calTrig: 'Calendar Trigger',
    triggeredFrom: 'Triggered From these processes',
    createProcess: 'Create Process',
    editProcess: 'Edit Process',
    serviceForProcess: 'Service for this Process',
    selectService: 'Select service',
    stepName: 'Step Name',
    linkedProcess: 'Linked Process',
    selectProcess: 'Select a Process',
    stepNotes: 'Step Notes',
    stepNotesExplain: 'Add any important information about this step that can serve as a context/reminder/etc',
    stepCondition: 'Step Condition',
    stepConditionExplain: 'Condition specify if certain circumstances need to be met in order to execute this step',
    stepContent: 'Step Content',
    stepInformation: 'Step Information',
    removeStep: 'Remove Step',
    linkedPolicy: 'Linked Policies',
    linkedPolicyMessage:
      'Policies that are relevant for this process. You can click multiple times to select more than one',
    processCategoryMessage: 'The category to group the process with others processes in a flow',
    processGoalMessage: 'The goal of the process, what does the process achieve?',
    processDescriptionMessage: 'The description of the process, what does (briefly) the process do?',
    processName: 'Process Name',
    saveBeforeTriggers: 'Save the process before adding triggers',
  },
  calTrig: {
    name: 'Calendar Trigger Name',
    namePlaceholder: 'Monthly Budget Review',
    startTime: 'Start Time',
    endTime: 'End Time',
    timezone: 'Timezone',
    guests: 'Guests (comma separated)',
    guestsPlaceholder: 'Guests for the event',
    noRepeat: 'No Repeat',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    years: 'Years',
    dayCriteria: 'Day Criteria',
    byNumber: 'By Day Number',
    byNumberMonth: 'By Day Number of Month',
    byDay: 'By Day of Week',
    byDayMonth: 'By Day of Week of Month',
    month: 'Month',
    repeatEvery: 'Repeat Every',
    repeatFor: 'Repeat Every',
    repeatBy: 'Repeat By',
    number: 'Number',
    frequency: 'Frequency',
    repeatUntil: 'Repeat Until',
    repeatPattern: 'Repeat Pattern',
    repeatPosition: 'Repeat Position',
    months: 'Months',
    every: 'Every',
    indefinitely: 'Indefinitely',
    afterTimes: 'End After X Times',
    atDate: 'At Date',
  },
  processMap: {
    title: 'Process Map',
    bezier: 'Bezier',
    straight: 'Straight',
    step: 'Step',
    lineStyle: 'Line Style',
    chartDetail: 'Chart detail level',
    detailedProcessLevel: 'Show detailed processes with steps inside',
    roughOutlineLevel: 'Show rough outline',
    v2: 'Show v2!',
    flowMargin: 'Flow Margin (px) - Vertical space between categories',
    categoriesAdded: 'Currently drawn categories',
    selectService: 'Select Service',
    selectArea: 'Select Area',
    selectCategory: 'Select Category',
    noCategoriesAddedYet: 'No categories added yet',
    noTriggersYet: 'No triggers defined for this flow',
  },
  services: {
    title: 'Services',
    addNew: 'Add New Service',
    edit: 'Edit Service',
    areaForService: 'Area for this Service',
    selectArea: 'Select area',
    createService: 'Create Service',
    editService: 'Edit Service',
    processesInside: 'Processes within this Service',
    pickAreaToSeeColors: 'Pick an area to see available colors',
    selectAColorArea: 'This color will be used to identify this area and shades of it will be used for services inside',
    selectAColorService: 'This color will be used to identify this service and processes inside',
  },
  stepItem: {
    notes: 'Notes (Optional)',
    selectProcess: 'Select Process',
    condition: 'Condition',
    conditionPlaceholder: 'If the reviewed item is not approved',
    requiredWithin: 'Required for steps within switch',
    linkedProcess: 'Linked Process',
    linkedProcessTiming: 'Linked Process Timing',
    linkedProcessMed: 'Linked Process Mediated by',
    deleteStep: 'Delete Step',
    editStep: 'Edit Step',
    dragStep: 'Drag Step',
    immediate: 'Immediate',
    linkEmail: 'Linked by email',
    linkCalendar: 'Linked by calendar',
    linkOther: 'Linked by other',
    conditionRequired: 'Condition is required for this step',
  },
  summaryCardProcess: {
    cannotReorder: 'Cannot reorder in processes from all services view, access a specific service to reorder',
    selectPolicy: 'Select Policy',
    noTriggers: 'No calendar / event triggers',
    noPolicies: 'No policies linked',
  },
  summaryCard: {
    cannotReorder: 'Cannot reorder in services from all areas view, access a specific area to reorder',
    integration: 'Integration',
    integrationInactiveMessage: 'You do not have an active integration',
    integrationOfferMessage: 'Would you like to set up your integration now?',
    emptyArea: 'This area contains no services inside',
    emptyService: 'This service contains no processes inside',
    emptyProcess: 'This process contains no steps inside',
    numberPolicies: 'Number of Policies linked to this process',
    numberCalendarTriggers: 'Number of Calendar Triggers for this process',
    numberEventTriggers: 'Number of Event Triggers for this process',
  },
  impExp: {
    enterData: 'Enter Data to be imported',
    resetIds: 'Reset IDs',
    resetInteg: 'Reset Integration Data',
    skipReorder: 'Skip Reorder',
    importPos: 'Import Position',
    beforeStep: 'Before Step',
    integDataMessage:
      'Note that disabling this will maintain integration data from the imported JSON, possibly creating inconsistencies',
    exportElem: 'Select Elements to Export',
  },
  menu: {
    areas: 'Areas',
    services: 'Services',
    categories: 'Categories',
    processes: 'Processes',
    policies: 'Policies',
    documents: 'Documents',
    processMap: 'Process Map',
    integrations: 'Integrations',
    organizations: 'Organizations',
    settings: 'Settings',
    myOrganization: 'My Organization',
    logout: 'Logout',
    users: 'Users',
    searchResultsMinLength: 'Type at least three characters',
    noResults: 'No results found.',
    stopImpersonate: 'Stop Impersonating',
    workingAs: 'Warning: currently working as',
    stopImpersonating: 'To allow collapse, first stop impersonating',
    onboarding: 'Onboarding',
    onboardingTasksCompleted: 'Onboarding Tasks completed',
    ftueCompleted: 'Interview Done',
    areaCreated: 'Area Created',
    serviceCreated: 'Service Created',
    processCreated: 'Process Created',
    processWritten: 'Process Written',
    dismissPermanently: 'Dismiss Permanently',
    dismissForTwoWeeks: 'Dismiss for 2 Weeks',
    dismissOnboarding: 'Dismiss Onboarding',
    dismissOnboardingText:
      'Are you sure you want to dismiss the onboarding steps reminder? Select if you wish to dismiss it permanently or for two weeks.',
    updateProfile: 'Your profile is more than 90 days old, we recommend going to "My organization" to update it.',
    collapseTooltip: 'Click to collapse sidebar',
    expandTooltip: 'Click to expand sidebar',
    searchTooltip: 'Click to search for processes, areas, services, etc.',
  },
  settings: {
    changeAvatar: 'Change Avatar',
    saveAvatar: 'Save Avatar',
    changePassword: 'Change Password',
    oldPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    updatePassword: 'Update Password',
    saveName: 'Save Name',
    saveLanguage: 'Save Language',
    english: 'English',
    spanish: 'Spanish',
    seeOrganizationData: 'See Organization Data',
    wolfieStatus: 'Mr. Wolf Assisstant Status',
    wolfieSuggestions: 'Mr. Wolf Assisstant Show Message Suggestions',
  },
  common: {
    action: 'Action',
    actions: 'Actions',
    active: 'Active',
    add: 'Add',
    alert: 'Alert',
    all: 'All',
    allAreas: 'All Areas',
    alreadyEditing: 'You are already editing a step, either cancel or save the edition. You are editing the step: ',
    area: 'Area',
    areas: 'Areas',
    assign: 'Assign',
    back: 'Back',
    beginning: 'Beginning',
    cancel: 'Cancel',
    cannotDelete: 'Cannot delete if there are related entities',
    case: 'Case',
    category: 'Category',
    calendarIntegration: 'Calendar Integration Active',
    calendarIntegrationInactive: 'Calendar Integration Inactive',
    changesNotSaved: 'Your changes are not yet saved, are you sure you want to leave?',
    clear: 'Clear',
    clickDelete: 'Click here to delete',
    clickEdit: 'Click here to edit',
    clickShowDoc: 'Click to show in google docs',
    clickShowMap: 'Click to show in process map',
    clickShowStorage: 'Click to show in storage integration',
    clickShowCalendar: 'Click to show in calendar integration',
    clickTo: 'Click to',
    clickToAddOne: 'Click here to add one.',
    clickToAttachWolfie: 'Click to attach a file to use as an input for Wolfie (Max size 5 MB)',
    clickView: 'Click here to view',
    clickViewGrid: 'Click to view in grid',
    clickViewList: 'Click to view in list',
    close: 'Close',
    collapse: 'Collapse',
    color: 'Color',
    condition: 'Condition',
    confirm: 'Confirm',
    confirmLeave: 'Confirm Leave',
    content: 'Content',
    create: 'Create',
    custom: 'Custom',
    delete: 'Delete',
    deleted: 'Deleted',
    description: 'Description',
    disable: 'Disable',
    disabled: 'Disabled',
    documents: 'Documents',
    dragToReorder: 'Drag to reorder',
    edit: 'Edit',
    email: 'Email',
    enable: 'Enable',
    enabled: 'Enabled',
    end: 'End',
    expand: 'Expand',
    export: 'Export',
    favorites: 'Favorites',
    filter: 'Filter',
    goal: 'Goal',
    help: 'Help',
    import: 'Import',
    impersonate: 'Impersonate',
    inside: 'Inside',
    link: 'Link',
    loop: 'Loop',
    name: 'Name',
    none: 'None',
    notNow: 'Not Now',
    openDrawer: 'Open Drawer',
    optional: 'Optional',
    options: 'Options',
    organization: 'Organization',
    owner: 'Owner',
    owners: 'Owners',
    policy: 'Policy',
    policies: 'Policies',
    preview: 'Preview',
    process: 'Process',
    processMap: 'Process Map',
    report: 'Report',
    requestError: 'Network request failed',
    requestSuccess: 'Request successful',
    required: 'Required',
    somethingWentWrong: 'Something went wrong, please refresh and try again.',
    save: 'Save',
    saving: 'Saving',
    search: 'Search',
    select: 'Select',
    send: 'Send',
    service: 'Service',
    share: 'Share',
    show: 'Show',
    showInDocs: 'Show in Google Docs',
    status: 'Status',
    step: 'Step',
    steps: 'Steps',
    storageIntegration: 'Storage Integration Active',
    storageIntegrationInactive: 'Storage Integration Inactive',
    submit: 'Submit',
    suggestions: 'Suggestions',
    toggleEdit: 'Toggle Edit',
    triggers: 'Triggers',
    uncategorized: 'Uncategorized',
    update: 'Update',
    uploading: 'Uploading',
    user: 'User',
    wolfie: 'Mr. Wolf',
    wolfieGenerated: 'This was generated by Mr. Wolf. Click to confirm.',
    yes: 'Yes',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    last: 'Last',
  },
  documents: {
    title: 'Documents',
    addDocument: 'Upload document',
    replaceDocument: 'Replace document',
    categoryInUse: 'This category is in use and cannot be deleted',
    upload: 'Upload',
    uploadDocument: 'Upload Document',
    previewText: 'File preview',
    previewUpdateText: 'Preview of the file to be updated (the old version will be REPLACED)',
    noPreview: 'Preview for this file type unavailable',
    confirmDelete: 'Confirm document deletion',
    confirmDeleteMessage: 'Are you sure you want to delete the document?',
    cantDelete: 'This file is linked to the following steps, remove them from steps first',
    documentDisabledSuccessfully: 'Document deleted successfully',
    documentUpdatedSuccessfully: 'Document updated successfully',
    downloadFile: 'Download File',
    owner: 'Owner',
    replaceWithNew: 'Replace this file with a new one (this will keep the same ID and existing links)',
    documentCategories: 'Document Categories',
    documentPreview: 'Document Preview',
    noVideoTag: 'Your browser does not support the video tag.',
  },
  organizations: {
    title: 'Organizations',
    organization: 'Organization',
    userInfo: 'User Information',
    ownersNames: 'Owners Names',
    ownersEmails: 'Owners Emails',
    addOrganization: 'Add Organization',
    editOrganization: 'Edit Organization',
    create: 'Create',
    enableOrganization: 'Enable Organization',
    disableOrganization: 'Disable Organization',
    confirmDisableMessage: 'Are you sure that you want to disable the organization?',
    confirmEnableMessage: 'Are you sure that you want to enable the organization?',
    lastProfileUpdate: 'Last Profile Update',
    mainAreas: 'Main Areas',
    troublesomeProcesses: 'Troublesome Processes',
    onboardingStatus: 'Onboarding Status',
    skippedUntil: 'Skipped Until',
    redoFTUE: 'Relaunch Interview',
    redoFTUEDescription: 'Relaunch the interview to update your organization data',
    interviewProfile: 'Interview Profile',
    showOrganizationData: 'Click to show organization profile page',
    pendingActions: 'Pending Actions',
    processCreation: 'Process Creation',
    stepCreation: 'Step Creation',
  },
  users: {
    title: 'Users',
    role: 'Role',
    firstName: 'First Name',
    status: 'Status',
    lastName: 'Last Name',
    addUser: 'Add User',
    disableUser: 'Disable User',
    editUser: 'Edit User',
    confirmDisableMessage: 'Are you sure that you want to disable the user?',
    confirmEnableMessage: 'Are you sure that you want to enable the user?',
    notAllowedToEdit: 'You are not allowed to edit this user',
    locale: 'Locale',
  },
  wolfie: {
    title: "Mr. Wolf's Insights",
    minimize: 'Minimize',
    closeDisable: 'Close and Disable (you can later reenable in settings)',
    enableSpeak: 'Enable speak mode (Mr. Wolf reads messages aloud)',
    enableVideo: 'Enable video avatar (Mr. Wolf will talk and have an avatar)',
    loading: 'Working on the answer...',
    loadingNoAreas: 'Working on your areas, almost ready...',
    loadingNoAreasInitialized: 'Setting up your adjusted areas, just a moment...',
    loadingBlankArea: 'Populating your area with some services...',
    loadingBlankAreaInitialized: 'Setting up your adjusted services, just a moment...',
    loadingBlankProcess: 'Working on your steps, just a moment...',
    loadingBlankProcessInitialized: 'Setting up your adjusted steps, just a moment...',
    loadingBlankService: 'Populating your service with some processes...',
    loadingBlankServiceInitialized: 'Setting up your adjusted processes, just a moment...',
    loadingFirstTimeUser: 'Setting up your first time user experience...',
    loadingFirstTimeUserInitialized: 'Updating your information, just a moment...',
    ftueMessage1: 'Welcome to Mr. Wolf',
    ftueMessage2: "Let's start by getting to know your organization.",
    ftueMessage3: 'Wolfie will guide you through a few questions to quickly get a sense of your organization.',
    ftueMessage4: 'Then we will proceed to set up the areas in your organization.',
    ftueMessage5: 'Of course, you can always make changes later and ask Wolfie to adjust the suggested data.',
    ftueCancelButton: 'No thanks, no FTUE',
    ftueStartButton: "OK, let's start!",
  },
};

export default en;
